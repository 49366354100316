import PolicyManager from '@/pages/policies/mfw/policy-manager/Main.vue'
import PolicyManagerOverview from '@/pages/policies/mfw/policy-manager/tabs/Overview.vue'
import Policies from '@/pages/policies/mfw/policy-manager/tabs/Policies.vue'
import Objects from '@/pages/policies/mfw/policy-manager/tabs/Objects.vue'
import ObjectsList from '@/pages/policies/mfw/policy-manager/objects/ObjectsList.vue'
import ObjectConditionsList from '@/pages/policies/mfw/policy-manager/objects/ObjectConditionsList.vue'

import Rules from '@/pages/policies/mfw/policy-manager/tabs/Rules.vue'
import PolicyEdit from '@/pages/policies/mfw/policy-manager/policies/EditPolicy.vue'
import PolicyRules from '@/pages/policies/mfw/policy-manager/policies/PolicyRules.vue'
import PolicyConditions from '@/pages/policies/mfw/policy-manager/policies/PolicyConditions.vue'
import Templates from '@/pages/policies/mfw/policy-manager/tabs/Templates.vue'
import Provisioning from '@/pages/policies/mfw/policy-manager/tabs/Provisioning.vue'
import Assignment from '@/pages/policies/mfw/policy-manager/tabs/Assignment.vue'

import {
  AllRules,
  EditCondition,
  EditConfiguration,
  EditObject,
  EditRule,
} from '@/pages/policies/mfw/policy-manager/editors/'

export const mfwPoliciesChildren = [
  {
    name: 'pm-overview',
    path: 'overview',
    component: PolicyManagerOverview,
  },
  {
    name: 'pm-policies',
    path: 'policies',
    component: Policies,
  },
  {
    name: 'pm-policy',
    path: 'policies/:policyId',
    component: PolicyEdit,
    children: [
      // policy conditions listing
      {
        name: 'pm-policy-conditions',
        path: 'conditions',
        component: PolicyConditions,
        meta: () => ({
          backRoute: {
            name: 'pm-policies',
          },
        }),
      },
      // policy rules listing for each rule type
      {
        name: 'pm-policy-rules',
        path: ':ruleType',
        component: PolicyRules,
      },
      {
        name: 'pm-policy-all-rules',
        path: ':ruleType/all-rules',
        component: AllRules,
        meta: route => ({
          backRoute: {
            name: 'pm-policy-rules',
            params: { ruleType: route.params.ruleType },
          },
        }),
      },
      // rule editor within policy
      {
        name: 'pm-policy-rules-rule',
        path: ':ruleType/:ruleId',
        component: EditRule,
        meta: route => ({
          backRoute: {
            name: 'pm-policy-rules',
            params: { ruleType: route.params.ruleType },
          },
        }),
      },
    ],
  },
  {
    name: 'pm-objects',
    path: 'objects',
    redirect: { name: 'pm-objects-condition-list' },
    component: Objects,
    children: [
      {
        name: 'pm-objects-condition-list',
        path: 'mfw-object-condition',
        component: ObjectConditionsList,
      },
      {
        name: 'pm-objects-list',
        path: ':objectType',
        component: ObjectsList,
      },
      {
        name: 'pm-objects-condition',
        path: ':objectType/:conditionId',
        component: EditCondition,
        meta: route => ({
          backRoute: {
            name: 'pm-objects-condition-list',
            params: { objectType: route.params.objectType },
          },
        }),
      },
      {
        name: 'pm-object',
        path: ':objectType/object/:objectId',
        component: EditObject,
        meta: route => ({
          backRoute: {
            name: 'pm-objects-list',
            params: { objectType: route.params.objectType },
          },
        }),
      },
    ],
  },
  {
    name: 'pm-rules',
    path: 'rules',
    component: Rules,
  },
  {
    name: 'pm-rules-rule',
    path: 'rules/:ruleId',
    component: EditRule,
    meta: () => ({
      backRoute: {
        name: 'pm-rules',
      },
    }),
  },
  {
    name: 'pm-templates',
    path: 'templates',
    component: Templates,
  },
  {
    name: 'pm-templates-configuration',
    path: 'templates/:configurationId',
    component: EditConfiguration,
    meta: () => ({
      backRoute: {
        name: 'pm-templates',
      },
    }),
  },
  {
    name: 'pm-provisioning',
    path: 'assignment/provisioning',
    component: Provisioning,
  },
  {
    name: 'pm-assignment',
    path: 'assignment',
    component: Assignment,
  },
]

export default [
  {
    path: '/appliances/me-policies',
    name: 'me-policies',
    component: PolicyManager,
    redirect: { name: 'pm-overview' },
    children: mfwPoliciesChildren,
  },
]
