<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules">
    <v-select
      v-model="value"
      :items="items"
      :multiple="multipleSelection"
      solo
      flat
      dense
      hide-details
      :placeholder="$t(placeholder)"
      :error-messages="errors"
      :menu-props="{ offsetY: true }"
    >
      <template #selection="{ item, index }">
        <template v-if="multipleSelection">
          <span v-if="index < othersIndex" class="caption font-weight-medium">
            {{ ($t(item.text) || item) + (value.length - 1 > index ? ',&nbsp;' : '') }}
          </span>

          <span v-if="index === othersIndex" class="grey--text text-caption">
            {{ $t('x_others', [value.length - othersIndex]) }}
          </span>
        </template>
        <template v-else>
          <span class="caption font-weight-medium">{{ $t(item.text) || item }}</span>
        </template>
      </template>

      <template #item="{ item, attrs, on }">
        <v-list-item v-slot="{ active }" v-bind="attrs" dense class="px-2" :ripple="false" v-on="on">
          <v-list-item-action v-if="multipleSelection" class="my-0 mr-2">
            <v-checkbox :input-value="active" dense :ripple="false" />
          </v-list-item-action>
          <v-list-item-content class="caption font-weight-medium px-2">
            {{ $t(item.text) || item }}
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
    </v-select>
  </ValidationProvider>
</template>
<script>
  import mixin from './mixin'
  export default {
    mixins: [mixin],
    props: {
      values: { type: Array, default: () => undefined },
      multiple: { type: Boolean, default: false },

      /**
       * the value being edited; that's because some conditions have additional data besides `value`
       * e.g.
       * CLIENT_PORT / SERVER_PORT have also `port_protocol`
       * LIMIT_RATE has also `rate_unit`
       */
      valueKey: { type: String, default: 'value' },
    },
    data() {
      return {
        othersIndex: 5,
      }
    },
    computed: {
      items: ({ values, conditionConfig }) => values || conditionConfig?.values,
      multipleSelection: ({ multiple, conditionConfig }) => multiple || conditionConfig?.multiple,

      value: {
        // returns the condition value array
        get: ({ condition, multipleSelection, valueKey }) => {
          if (valueKey === 'value') {
            return multipleSelection ? condition.value : condition.value[0]
          } else {
            return condition[valueKey]
          }
        },
        /**
         * Sets the value as a single array entry or multiple depending on the condition configuration
         * @param {Array} value - array of one or multiple values as strings/numbers
         */
        set(value) {
          if (this.valueKey === 'value') {
            if (this.multipleSelection) this.condition.value = value
            else this.condition.value = [value]
          } else {
            this.condition[this.valueKey] = value
          }
        },
      },
    },
  }
</script>
