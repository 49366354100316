<template>
  <ValidationProvider v-slot="{ errors }" :rules="condition.type ? 'required' : ''" class="d-flex align-center">
    <condition-operator-info :op="condition.op" />
    <v-select
      v-model="condition.op"
      :disabled="!condition.type"
      :items="operators"
      dense
      solo
      flat
      hide-details
      :placeholder="$t('comparison')"
      :error-messages="errors"
      :menu-props="{ offsetY: true }"
      @input="resetValueOrObject"
    >
      <template #selection="{ item }">
        {{ $t(item.toLowerCase()) }}
      </template>
      <template #item="{ item }">
        {{ $t(item.toLowerCase()) }}
      </template>
      <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
    </v-select>
  </ValidationProvider>
</template>
<script>
  import { conditionsConfig, eqOps, inOps, matchOps } from 'vuntangle/pm'
  import ConditionOperatorInfo from './ConditionOperatorInfo.vue'

  export default {
    components: { ConditionOperatorInfo },
    inject: ['$condition'],

    data() {
      return {
        currentOp: undefined,
      }
    },

    computed: {
      condition: ({ $condition }) => $condition(),
      operators: ({ condition }) => conditionsConfig[condition.type]?.operators,
    },

    watch: {
      'condition.op': {
        handler(op) {
          if (op) this.currentOp = op
        },
        immediate: true,
      },
    },

    methods: {
      /** on op change switch value/object */
      resetValueOrObject(newOp) {
        if (eqOps.includes(newOp)) {
          /** reset value only if new op different type eg. match or in */
          if (!eqOps.includes(this.currentOp)) {
            this.$set(this.condition, 'value', [])
          }
          this.$delete(this.condition, 'object')
        }

        if (matchOps.includes(newOp)) {
          /** reset value only if new op different type eg. eq or in */
          if (!matchOps.includes(this.currentOp)) {
            this.$set(this.condition, 'object', [])
          }
          this.$delete(this.condition, 'value')
        }

        if (inOps.includes(newOp)) {
          /** reset value only if new op different type eg. eq or match */
          if (!inOps.includes(this.currentOp)) {
            this.$set(this.condition, 'object', [])
          }
          this.$delete(this.condition, 'value')
        }

        this.currentOp = newOp
      },
    },
  }
</script>
