<template>
  <div class="d-flex flex-column flex-grow-1 align-stretch pa-0">
    <grid-wrapper
      ref="wrapper"
      :headline="$t(config.text)"
      :selection.sync="selection"
      :show-delete="objects.length > 0 && !config.readOnly"
    >
      <template #actions>
        <v-btn v-if="!config.readOnly" depressed color="primary" class="text-capitalize" @click="onCreateObject(null)">
          <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_new') }}
        </v-btn>
        <v-btn
          v-if="defaultConfigs"
          depressed
          color="primary"
          class="text-capitalize ml-2"
          :disabled="fetching"
          @click="onCreateDefaults"
        >
          <v-icon small>mdi-plus</v-icon>
          {{ $t('create_defaults') }}
        </v-btn>
      </template>
      <template #default="{ selectionType }">
        <u-grid
          :id="`policy-objects-${routeKey}`"
          :key="`policy-objects-${routeKey}`"
          ref="objectsGrid"
          :selection-type="selectionType"
          row-node-id="id"
          :no-data-message="noDataMessage"
          :column-defs="columnDefs"
          :fetching="fetching"
          :row-data="objects"
          :row-actions="rowActions"
          :selection.sync="selection"
          :framework-components="frameworkComponents"
          v-on="!selectionType ? { rowClicked } : {}"
          @refresh="fetchObjectsAndGroups(true)"
        >
          <template v-if="config.autoHideGeneratedObjects" #toolbarActions>
            <v-divider vertical class="mx-4" />
            <v-switch v-model="showAutoGenerated" dense hide-details class="mr-1 mt-0 pt-0">
              <template #label>
                <span class="text-caption">{{ $t('show_autogenerated') }}</span>
              </template>
            </v-switch>
          </template>
        </u-grid>
      </template>
    </grid-wrapper>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { columnDefs, objectsConfig, Type, ObjectValueRenderer, NameRenderer } from 'vuntangle/pm'
  import GridWrapper from '../components/GridWrapper.vue'
  import { generateDefaultMFWObject, getFilteredObjects } from '../util'
  import { hydrateObjectsData } from '../hydration'

  export default {
    components: { GridWrapper },
    data() {
      return {
        fetching: false,
        // to filter in / out the auto gen objects via the toggle
        showAutoGenerated: true,
        selection: [],
        frameworkComponents: {
          ObjectValueRenderer,
          NameRenderer,
        },
        rowActions: [],
      }
    },
    computed: {
      routeKey: ({ $route }) => $route.params.objectType,
      config: ({ routeKey }) => objectsConfig[routeKey],
      type: ({ config, routeKey }) => config.objectType || routeKey,

      defaultConfigs: ({ config }) => config.defaults,
      // if it's a group, it will have a linked object type for displaying dependencies
      linkedObjectType: ({ config }) => config.linkedObjectType,
      objects: ({ $store, config, type, showAutoGenerated }) => {
        const objects = getFilteredObjects(
          $store.getters['policyManager/getObjectsByType'](type),
          config.readOnly,
          showAutoGenerated,
        )
        return hydrateObjectsData(objects)
      },
      isCondition: ({ type }) => type === Type.ObjectCondition,
      isGroup: ({ type }) => type.includes('group'),
      columnDefs: () => columnDefs.getObjectsColumnDefs(),
      noDataMessage({ config }) {
        return this.objects.length > 0
          ? this.$t('no_filtered_data_' + config.text)
          : this.$t('no_data_defined_' + config.text)
      },
    },

    watch: {
      type: {
        handler() {
          this.fetchObjectsAndGroups()
          this.selection = []
          this.showAutoGenerated = !this.config.autoHideGeneratedObjects
        },
        immediate: true,
      },

      config: {
        /**
         * Sets or removes row floating actions based on `readOnly` object config
         * e.g. AGNI/UNO objects are readOnly
         * @param {Object} config - object configuration
         */
        handler(config) {
          if (!config || config.readOnly) {
            this.rowActions = []
          } else {
            this.rowActions = [
              {
                icon: 'mdi-pencil',
                handler: ({ node }) => this.rowClicked({ node }),
              },
              {
                icon: 'mdi-content-copy',
                handler: ({ data }) => this.onCreateObject(data.id),
              },
              {
                icon: 'mdi-delete',
                handler: ({ data }) => this.$refs.wrapper.onDelete([data.id]),
              },
            ]
          }
        },
        immediate: true,
      },
    },

    methods: {
      /**
       * Fetch data objects in parallel
       * @param {Boolean} force - refetches data from mongo if true
       */
      async fetchObjectsAndGroups(force = false) {
        const fns = [this.$store.dispatch('policyManager/fetchObjectsByType', { type: this.type, force })]
        if (this.linkedObjectType) {
          fns.push(this.$store.dispatch('policyManager/fetchObjectsByType', { type: this.linkedObjectType, force }))
        }
        fns.push(this.$store.dispatch('policyManager/fetchDependencyMap', { force }))
        this.fetching = true
        await Promise.allSettled(fns)
        this.fetching = false
      },

      rowClicked({ node }) {
        if (this.config.readOnly) {
          return
        }
        const objectId = node.data.id
        const object = this.$store.getters['policyManager/getObjectById'](objectId)

        if (!object) return

        this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(object))
        this.$router.push({ name: 'pm-object', params: { objectId } })
      },

      /**
       * Creates new or duplicates existing object then goes to the editing view for it
       * @param {String} id - the object id to be duplicated
       * @returns {undefined}
       */
      onCreateObject(id) {
        const object = id && this.$store.getters['policyManager/getObjectById'](id)

        const newObject = generateDefaultMFWObject(this.type, object)

        this.$store.commit('policyManager/SET_EDIT_OBJECT', newObject)
        this.$router.push({ name: 'pm-object', params: { objectId: this.routeKey } })
      },

      /**
       * Creates the default objects and saves them in DB
       * @returns {Promise<void>}
       */
      async onCreateDefaults() {
        this.fetching = true
        let isSuccess = true
        for (const object of cloneDeep(this.defaultConfigs)) {
          const response = await this.$store.dispatch('policyManager/saveObject', { object, autoFetch: false })
          // isSuccess will become true if all calls pass and false otherwise
          isSuccess = isSuccess && !!response
        }
        // passing in 2 to show plural version of string
        const itemType = this.$tc('default', 2)
        if (isSuccess) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [itemType]))
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [itemType]), 'error')
        }

        this.fetching = false

        this.fetchObjectsAndGroups(true)
      },
    },
  }
</script>
