<template>
  <div>
    <u-checkbox
      v-model="templateModel.keepInSyncEnabled"
      class="px-0"
      :label="$t('keep_in_sync')"
      :hint="$t('keep_in_sync_hint')"
      persistent-hint
      @change="$emit('update:selectedValues', templateModel)"
    />

    <div class="d-flex flex-row justify-left mt-4">
      <u-select
        v-model="templateModel.syncFrequency"
        :items="syncFrequencyItems"
        :disabled="!templateModel.keepInSyncEnabled"
        :label="$t('sync_frequency')"
        class="flex-grow-0 mr-4"
        @change="$emit('update:selectedValues', templateModel)"
      />
      <u-select
        v-if="templateModel.syncFrequency === 2"
        v-model="templateModel.syncDayOfWeek"
        :items="syncDayOfWeekItems"
        :disabled="!templateModel.keepInSyncEnabled"
        :label="$t('day_of_the_week')"
        class="flex-grow-0 mr-4"
        @change="$emit('update:selectedValues', templateModel)"
      />
      <u-autocomplete
        v-if="templateModel.syncFrequency !== 0"
        v-model="templateModel.syncTimeOfDay"
        :items="syncTimeOfDayItems"
        :disabled="!templateModel.keepInSyncEnabled"
        :label="$t('time_of_day')"
        class="flex-grow-0"
        @change="$emit('update:selectedValues', templateModel)"
      />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      /*
       * Prop to set the drop-downs with the selected values
       */
      selectedValues: {
        type: Object,
        required: true,
        // validate the object has the correct properties
        validator(selectedValues) {
          if (
            typeof selectedValues.keepInSyncEnabled !== 'boolean' ||
            typeof selectedValues.syncAllNewAppliancesEnabled !== 'boolean' ||
            typeof selectedValues.syncFrequency !== 'number' ||
            typeof selectedValues.syncDayOfWeek !== 'number' ||
            typeof selectedValues.syncTimeOfDay !== 'number'
          ) {
            return false
          }

          return true
        },
      },
      syncAllAlreadyEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        templateModel: Object.assign({}, this.selectedValues),
        displaySyncAllAlreadyEnabled: this.syncAllAlreadyEnabled,
      }
    },
    computed: {
      syncFrequencyItems() {
        return [
          { text: this.$t('immediately'), value: 0 },
          { text: this.$t('daily'), value: 1 },
          { text: this.$t('weekly'), value: 2 },
        ]
      },
      syncDayOfWeekItems() {
        return [
          { text: this.$t('sunday'), value: 0 },
          { text: this.$t('monday'), value: 1 },
          { text: this.$t('tuesday'), value: 2 },
          { text: this.$t('wednesday'), value: 3 },
          { text: this.$t('thursday'), value: 4 },
          { text: this.$t('friday'), value: 5 },
          { text: this.$t('saturday'), value: 6 },
        ]
      },

      /**
       * Build an array of the time of day every 15 minutes for the frequency
       * drop down.
       *
       * @return {Object[]}
       */
      syncTimeOfDayItems() {
        const results = []

        // increment every 15 minutes
        for (let minutes = 0; minutes <= 1425; minutes += 15) {
          let hours = Math.floor(minutes / 60)
          const meridiem = hours < 12 ? 'AM' : 'PM'
          const minutesForHour = minutes - hours * 60

          // set hours for AM/PM
          if (hours === 0) {
            hours = 12
          } else if (hours > 12) {
            hours -= 12
          }

          results.push({
            text: `${hours.toString().padStart(2, '0')}:${minutesForHour.toString().padStart(2, '0')} ${meridiem}`,
            value: minutes * 60,
          })
        }

        return results
      },
    },
    watch: {
      selectedValues: {
        deep: true,
        handler(selectedValues) {
          this.templateModel = Object.assign({}, selectedValues)
        },
      },
      syncAllAlreadyEnabled(value) {
        this.displaySyncAllAlreadyEnabled = value
      },
    },
    methods: {
      /**
       * Action when clicking 'Sync All New Appliances', will update the
       * parent and check if the validation error can go away.
       *
       * @param {boolean} value
       *
       * @return {void}
       */
      clickSyncAllNewAppliances(value) {
        this.$emit('update:selectedValues', this.templateModel)

        if (!value) {
          this.$emit('update:syncAllAlreadyEnabled', false)
          this.displaySyncAllAlreadyEnabled = false
        }
      },
    },
  }
</script>
