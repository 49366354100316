<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules">
    <v-text-field
      v-model.number="value"
      type="number"
      outlined
      dense
      hide-details
      :error-messages="errors"
      :placeholder="$t('value')"
    >
      <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
    </v-text-field>
  </ValidationProvider>
</template>
<script>
  import mixin from './mixin'
  export default {
    mixins: [mixin],

    computed: {
      value: {
        // condition value is an array with a single item, so use that as the number field value
        get: ({ condition }) => condition.value[0],
        /**
         * sets the condition value as a single array number item
         * @param {Array} value - array with a single number entry
         */
        set(value) {
          this.condition.value = [value]
        },
      },
    },
  }
</script>
