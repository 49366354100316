import cloneDeep from 'lodash/cloneDeep'
import { miscConfig, objectsConfig, rulesConfig, SyncStatus, templatesConfig, Type } from 'vuntangle/pm'

import vuntangle from '@/plugins/vuntangle'

import i18n from '@/plugins/vue-i18n'
import store from '@/store'
import { getKeyFromPolicyType, mfwServices } from '@/util/mfwServices'

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  )
}

/**
 * returns the key translation for the object
 * @param {String} type - the object type
 */
function getObjectLabelByType(type) {
  return (
    objectsConfig[type]?.text ||
    rulesConfig[type]?.text ||
    templatesConfig[type]?.text ||
    miscConfig[type]?.text ||
    mfwServices[getKeyFromPolicyType(type)]?.tkey ||
    'unknown'
  )
}

/**
 * returns a list of MFW appliances and the policies assigned to them
 *
 * @returns {*|*[]}
 */
function getPolicyManagerAppliances() {
  let appliances = store.getters['appliances/listByType']('MFW')
  const assignments = store.getters['policyManager/getApplianceAssignment']
  const policies = store.getters['policyManager/getObjectsByType'](Type.Policy)

  if (!appliances) return []

  // only filter appliances with version >= 5.1
  appliances = appliances?.filter(item => parseFloat(item.SoftwareVersion) >= 5.1)

  return appliances.map(app => {
    const applianceAssignments = assignments.filter(({ appliance_id: uid }) => uid === app.Uid) || []
    return {
      ...app,
      Policies: applianceAssignments
        .map(({ object_id: pid }) => policies.find(({ Id }) => Id === pid))
        .filter(policy => policy),
      GlobalTemplates: applianceAssignments
        .filter(({ object_type: type }) => type.startsWith('mfw-config-global'))
        .map(({ object_id: id }) => store.getters['policyManager/getObjectById'](id))
        .filter(config => config),
      LastUpdated: findLatestTimeStamp(applianceAssignments.map(({ timestamp }) => timestamp)),
      SyncStatus: findApplianceSyncStatus(applianceAssignments),
      LastSynced: findLatestTimeStamp(applianceAssignments.map(({ last_synced: d }) => d)),
    }
  })
}

/**
 * returns one of following sync status
 * for the appliance based on status of its assignments
 *
 * synced, not_synced, syncing, unassigning or undefined (when no policies are assigned to an appliance)
 *
 * @param {Array} assignments list of assignments
 * @returns string appliance's sync status
 */
function findApplianceSyncStatus(assignments = []) {
  // return unassigning if any assignment is unassigning
  if (assignments.some(({ sync_status: status }) => status === SyncStatus.Unassigning)) {
    return SyncStatus.Unassigning
  }
  // return not_synced if any assignment is not_synced and none is unassigning
  if (assignments.some(({ sync_status: status }) => status === SyncStatus.NotSynced)) {
    return SyncStatus.NotSynced
  }
  // return syncing if no assignment is not_synced / unassigning and at least one is syncing
  if (assignments.some(({ sync_status: status }) => status === SyncStatus.Syncing)) {
    return SyncStatus.Syncing
  }
  // return synced if all assignments are synced
  if (assignments.length && assignments.every(({ sync_status: status }) => status === SyncStatus.Synced)) {
    return SyncStatus.Synced
  }
  // return undefined by default
}

/**
 * returns the latest date from passed timestamp array
 *
 * @param {Array} dateArray array of date timestamps
 * @returns latest date from passed array
 */
function findLatestTimeStamp(dateArray = []) {
  // only consider non null dates
  const dates = dateArray.filter(d => !!d)
  if (dates.length) {
    return Math.max(...dates)
  }
}

/**
 * finds all matching types against the prefix
 * @param {string} prefix prefix to search by e.g. mfw-rule
 * @returns array of matching types
 */
function findMFWTypesByPrefix(prefix) {
  return Object.values(Type).filter(key => key.startsWith(prefix))
}

/**
 * takes in the id of an object, returns the policies that depend on said object
 * @param id - id of an object (rule, condition, etc.)
 * @returns policies - an array of Policies
 */
export function getPoliciesByDependencyId(id) {
  const policyIds = store.getters['policyManager/getPolicyIdsByDependencyId'](id)
  return store.getters['policyManager/getObjectsByTypeIds'](Type.Policy, policyIds)
}

/**
 * generates a default empty object or a copy of an existing one against the given type
 *
 * @param {string} type - type of the mfw object to create
 * @param {Object} object - (optional) the object that is going to be duplicated
 * @returns Object - default skeleton mfw object
 */
const generateDefaultMFWObject = (type, object) => {
  // date object having the following format: May 7, 2024, 7:13 PM
  const dateTimeObj = vuntangle.dates.formatLocaleDate(new Date(), true)
  const newObject = {
    Id: type,
    Name: object ? `${object.Name} - ${dateTimeObj}` : '',
    Description: object?.Description || '',
    Type: type,
    PolicyJson: { items: [] },
  }

  if (object) {
    newObject.PolicyJson = cloneDeep(object.PolicyJson)
  } else if (templatesConfig[type]) {
    newObject.PolicyJson = cloneDeep(templatesConfig[type].defaultSettings)
  } else if (rulesConfig[type]) {
    newObject.PolicyJson = cloneDeep(rulesConfig[type].defaultRule)
  } else if (type === Type.Policy) {
    newObject.Name = i18n.t('new')
    newObject.PolicyJson = {
      rules: {},
      disabledRules: [],
      conditions: [],
    }
  }
  return newObject
}

/**
 * Display the objects in the grid.
 * Filters the results based on readonly (property in the objectsConfig.js)
 * and showAutoGen flag ( filters out objects starting with name "Autogenerated ")
 *
 * @param {Array} objects
 * @param {Boolean|undefined} readOnly
 * @param {Boolean|undefined} showAutoGenerated
 *
 * @returns {*|*[]}
 */
export function getFilteredObjects(objects, readOnly, showAutoGenerated) {
  // check if the results do not need to be filtered by a 'read only' type used for dynamic data
  if (!showAutoGenerated) {
    objects = filterAutoGeneratedMFWObjects(objects)
  }
  if (readOnly === undefined) {
    return objects
  }

  return objects.filter(object => {
    const objectReadOnly = object.PolicyJson.readOnly || false
    return readOnly === objectReadOnly
  })
}

/**
 * Removes all objects from the passed in list which starts with name `Autogenerated `
 * @param {Array} objectList object list to filter
 * @returns filtered List
 */
const filterAutoGeneratedMFWObjects = (objectList = []) => {
  return objectList.filter(({ Name }) => !Name.startsWith('Autogenerated '))
}

/**
 * shows confirmation dialog and on cofirm dispatches the action to delete objects from db
 *
 * @param {Array} objects List of objects to be deleted
 * @param {boolean} isPolicyOrTemplate boolean to determine if passed objects are policies or templates
 * @param {*} callback callback to handle any custom logic after delete operation
 */
const deleteMFWObjects = (objects, isPolicyOrTemplate = false, callback = () => {}) => {
  vuntangle.confirm.show({
    title: i18n.t('confirm'),
    message: i18n.t('confirm_delete_data'),
    confirmLabel: i18n.t('yes'),
    cancelLabel: i18n.t('no'),
    action: async resolve => {
      const response = await store.dispatch('policyManager/deletePoliciesOrTemplatesIfUnused', {
        objects,
        isPolicyOrTemplate,
      })
      callback(response)
      resolve()
      // don't show any toast if there are dependencies
      if (response.data?.dependencies) return
      // show success / failure toast
      if (response.data?.deleted) {
        vuntangle.toast.add(i18n.t('delete_success', [i18n.t('items')]))
      } else {
        vuntangle.toast.add(i18n.t('delete_failure', [i18n.t('items')]), 'error')
      }
    },
  })
}

export {
  uuidv4,
  getObjectLabelByType,
  getPolicyManagerAppliances,
  findMFWTypesByPrefix,
  generateDefaultMFWObject,
  filterAutoGeneratedMFWObjects,
  deleteMFWObjects,
}
