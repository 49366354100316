<!--
  Component showing Policy rules by type
  It also allows to remove/add/update existing Rules
-->
<template>
  <grid-wrapper
    ref="wrapper"
    :headline="$t(config.text)"
    :subtitle="$t(subtitle)"
    :selection.sync="selection"
    :permanent-delete="false"
    :show-delete="rules.length > 0"
    class="pa-4"
    @delete="onDelete"
  >
    <template #actions>
      <v-btn depressed color="primary" class="text-capitalize mr-2" @click="onAddExistingRule">
        <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('add_existing') }}
      </v-btn>
      <v-btn depressed color="primary" class="text-capitalize" @click="onCreateRule(null)">
        <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_new') }}
      </v-btn>
    </template>
    <template #default="{ selectionType }">
      <!--
      Grid showing Policy rules of a specific type
      The rules can be manually ordered via drag/drop.
      That's why the filtering and sorting of the data has been disabled. The Grid toolbar is not even show.
    -->
      <u-grid
        :id="`policy-rules-${ruleType}`"
        :selection-type="selectionType"
        row-node-id="id"
        :no-data-message="noDataMessage"
        :column-defs="columnDefs"
        :custom-ordering="true"
        :fetching="fetching"
        :row-data="rulesCopy"
        :row-actions="rowActions"
        :selection.sync="selection"
        :framework-components="frameworkComponents"
        :custom-grid-options="{
          onRowDragEnd: updateRulesOrder,
        }"
        v-on="!selectionType ? { rowClicked } : {}"
        @refresh="fetchRulesAndTemplates(true)"
      />
    </template>
  </grid-wrapper>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import {
    columnDefs,
    rulesConfig,
    ConditionsRenderer,
    OrderRenderer,
    ActionRenderer,
    ConditionGroupsRenderer,
    NameRenderer,
  } from 'vuntangle/pm'
  import GridWrapper from '../components/GridWrapper.vue'
  import { generateDefaultMFWObject } from '../util'
  import renderersMixin from '../renderersMixin'
  import { hydrateRulesData } from '../hydration'
  import policyMixin from './_policyMixin'

  export default {
    components: { GridWrapper },
    mixins: [policyMixin, renderersMixin],
    data() {
      return {
        selection: [],
        frameworkComponents: {
          OrderRenderer,
          ConditionsRenderer,
          ActionRenderer,
          ConditionGroupsRenderer,
          NameRenderer,
        },
        rulesCopy: [], // rules shown on the grid

        rowActions: [
          {
            icon: 'mdi-pencil',
            handler: ({ node }) => this.rowClicked({ node }),
          },
          {
            icon: 'mdi-content-copy',
            handler: ({ data }) => this.onCreateRule(data.id),
          },
          {
            icon: 'mdi-delete',
            handler: ({ data }) => this.$refs.wrapper.onDelete([data.id]),
          },
        ],
      }
    },
    computed: {
      ruleType: ({ $route }) => $route.params.ruleType,

      config: ({ ruleType }) => rulesConfig[ruleType],
      /**
       * the rules of a given type for the selected Policy
       * Those are set on policy data { rules: { mfw-policy-<type...>: ['guid1', 'guid2', ...] } }
       */
      rules: ({ $store, policy, ruleType }) => {
        // get rules Ids of that type
        const rulesIds = policy?.PolicyJson.rules[ruleType]
        const rules = []
        // get rules in the order they are set in the guids array
        rulesIds?.forEach(id => {
          const rule = $store.getters['policyManager/getObjectById'](id)
          if (rule) rules.push(rule)
        })
        return rules
      },

      subtitle: ({ ruleType }) => rulesConfig[ruleType].subtitle,

      // gets fetching status for the specific rule type
      fetching: ({ $store, ruleType }) => $store.getters['policyManager/fetching'](ruleType),

      columnDefs: ({ augmentColumns }) => augmentColumns(columnDefs.getPolicyRulesColumnDefs(), ['action']),

      noDataMessage({ config }) {
        return this.rules.length > 0
          ? this.$t('no_filtered_data_' + config.text)
          : this.$t('no_data_defined_' + config.text)
      },
    },
    watch: {
      /** Upon type change (equiv. with route params change) just fetch policies(rules) of this type */
      ruleType: {
        handler(type) {
          if (!type) return
          this.fetchRulesAndTemplates()
        },
      },

      rules: {
        /**
         * Hydrates the grid rules data, based on rules coming from mongo
         * @param rules - the rules as set in mongo db
         */
        handler(rules) {
          this.rulesCopy = hydrateRulesData(rules, this.policy)
        },
        immediate: true,
      },

      rulesCopy: {
        /**
         * Tracks the enabled flag on the grid rules and upates the `disabledRules` on the Policy
         * @param gridRules - array of rules from the grid
         */
        handler(gridRules) {
          if (!this.policyCopy) return
          // the ids of the rules being disabled
          const enabledRules = gridRules.filter(rule => rule.enabled).map(rule => rule.id)
          const disabledRules = gridRules.filter(rule => !rule.enabled).map(rule => rule.id)

          if (disabledRules.length) {
            // if policy does not have `disabledRules` prop at all just set it on it
            if (!this.policyCopy.PolicyJson.disabledRules) {
              this.$set(this.policyCopy.PolicyJson, 'disabledRules', disabledRules)
            }
            // check if disabled rule id already in list, otherwise add it
            disabledRules.forEach(ruleId => {
              if (!this.policyCopy.PolicyJson.disabledRules.includes(ruleId))
                this.policyCopy.PolicyJson.disabledRules.push(ruleId)
            })
          }

          // check if enabled rules exists in the list, if so remove it
          enabledRules.forEach(ruleId => {
            const idx = this.policyCopy.PolicyJson.disabledRules?.indexOf(ruleId)
            if (idx >= 0) this.policyCopy.PolicyJson.disabledRules.splice(idx, 1)
          })

          // if an old policy not having `disabledRules` prop, delete the prop
          if (!this.originalPolicy?.PolicyJson.disabledRules && !this.policyCopy.PolicyJson.disabledRules?.length) {
            this.$delete(this.policyCopy.PolicyJson, 'disabledRules')
          }
        },
        deep: true,
        immediate: true,
      },
    },

    mounted() {
      this.fetchRulesAndTemplates(true)
    },

    methods: {
      /** dispatch fetching rules of given type  */
      fetchRulesAndTemplates(force = false) {
        this.$store.dispatch('policyManager/fetchObjectsByType', { type: this.ruleType, force })
        this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config', force })
      },

      /** commits the rule that is going to be edited, than routes to the rule editor */
      rowClicked({ node }) {
        if (this.selectionType) return
        const ruleId = node.data.id
        const rule = this.$store.getters['policyManager/getObjectById'](ruleId)
        if (!rule) return

        this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(rule))
        this.$router.push({ name: 'pm-policy-rules-rule', params: { ruleId } })
      },

      onAddExistingRule() {
        this.$router.push({ name: 'pm-policy-all-rules' })
      },

      /**
       * Upon attempting to add a new rule the editor is displayed at the selection of the existing rules
       * @param {String} id - the object (rule) id to be duplicated
       */
      onCreateRule(id) {
        const rule = id && this.$store.getters['policyManager/getObjectById'](id)

        this.$store.commit('policyManager/SET_EDIT_OBJECT', generateDefaultMFWObject(this.ruleType, rule))
        this.$router.push({ name: 'pm-policy-rules-rule', params: { ruleId: this.ruleType } })
      },

      /** removes selected conditions from the rule than make sure grid is deselected */
      onDelete(ids) {
        const policyRules = this.policyCopy.PolicyJson.rules[this.ruleType]
        const disabledRules = this.policyCopy.PolicyJson.disabledRules

        ids.forEach(id => {
          const index = policyRules.indexOf(id)
          if (index >= 0) policyRules.splice(index, 1)

          // upon removing a rule, remove it also fron disabled array if disabled
          if (disabledRules?.length) {
            const indexDisabled = disabledRules.indexOf(id)
            if (indexDisabled >= 0) disabledRules.splice(indexDisabled, 1)
          }
        })
      },

      /**
       * Handles the ag-grid `onRowDragEnd` event which holds the new order of items
       * @param {Object} gridEvent - ag grid event when order is changed
       */
      updateRulesOrder(gridEvent) {
        const orderedRulesIds = []
        /** just extract the Ids using the grid api `forEachNode` */
        gridEvent.api.forEachNode(node => {
          orderedRulesIds.push(node.data.id)
        })
        /**
         * than update those in cloned policyCopy
         * User will have to act on "Update Order" button to trigger the actual saving to the cloud
         */
        this.policyCopy.PolicyJson.rules[this.ruleType] = orderedRulesIds
      },
    },
  }
</script>
