<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules">
    <v-radio-group v-model="value" row class="mx-1 my-0 pa-0" hide-details :error-messages="errors">
      <v-radio :label="$vuntangle.$t('yes')" :value="true"></v-radio>
      <v-radio :label="$vuntangle.$t('no')" :value="false"></v-radio>
    </v-radio-group>
  </ValidationProvider>
</template>
<script>
  import mixin from './mixin'
  export default {
    mixins: [mixin],
    computed: {
      value: {
        get: ({ condition }) => condition.value[0],
        set(value) {
          this.condition.value = [value]
        },
      },
    },
  }
</script>
