<template>
  <v-hover v-slot="{ hover }">
    <ValidationProvider v-slot="{ errors }" :rules="rules">
      <!--  Destination DNS Hint, Destination Interface, Source Interface, VLAN Tag-->
      <!--  set max length to 65-->
      <v-text-field
        v-model="value"
        flat
        solo
        dense
        hide-details
        :error-messages="errors"
        maxlength="65"
        :placeholder="$t(placeholder)"
      >
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
        <!-- appends Create Object inside the value field -->
        <template v-else-if="hover && showCreateObject" #append>
          <v-btn
            small
            text
            style="margin-top: -2px; margin-right: -6px"
            color="primary"
            class="text-capitalize"
            @click="$emit('create-object', conditionConfig.objectType, value)"
          >
            {{ $t('create_object') }}
          </v-btn>
        </template>
      </v-text-field>
    </ValidationProvider>
  </v-hover>
</template>
<script>
  import mixin from './mixin'
  export default {
    mixins: [mixin],
    props: {
      // Flag to enable multilpe values (for IP Addresses and ports)
      multiple: { type: Boolean, default: false },
    },
    computed: {
      value: {
        /**
         * stringifies the condition's array value into string
         * ['1'] => '1' or ['1','2','3'] => '1, 2, 3'
         * @param { condition } the condition object with value which will be an array with one or more values
         */
        get: ({ condition }) => condition.value?.join(', '),
        /**
         * maps stringified value back to an array
         * @param {string} value the new value
         */
        set(value) {
          // if multiple, then split by delimeter
          if (this.multiple) {
            this.condition.value = value?.split(',').map(v => v.trim())
          } else {
            // else create an array with one item i.e. the value
            this.condition.value = [value.trim()]
          }
        },
      },
    },
  }
</script>
