<template>
  <div v-if="boxSettings && boxSettings.policy_manager" class="d-flex flex-column fill-height">
    <h1 class="headline ml-4 mt-4">{{ $t('policies') }}</h1>
    <div class="align-center ml-4 mt-2 pr-4">
      <v-divider />
      <v-tabs v-model="activeTab" class="mt-2">
        <v-tab v-for="tab in ['policies', 'rules']" :key="tab" class="text-capitalize">
          {{ $t(tab) }}
        </v-tab>
      </v-tabs>
    </div>
    <!-- Tabs content -->
    <appliance-policies
      v-if="activeTab === 0"
      :box-settings="boxSettings"
      :show-action-column="true"
      @refresh="refreshSettings"
      @row-clicked="policyClicked"
      @assignment-clicked="assignmentClicked"
    />

    <appliance-rules
      v-if="activeTab === 1"
      :box-settings="boxSettings"
      @refresh="refreshSettings"
      @row-clicked="ruleClicked"
    />
  </div>
</template>

<script>
  import { AppliancePolicies, ApplianceRules } from 'vuntangle/pm'
  import settingsMixin from './settingsMixin'

  export default {
    components: { AppliancePolicies, ApplianceRules },
    mixins: [settingsMixin],
    data() {
      return {
        activeTab: 0,
      }
    },
    created() {
      this.refreshSettings()
    },
    methods: {
      refreshSettings() {
        this.$store.dispatch('appliances/fetchApplianceSettings', this.appliance)
      },
      policyClicked(id) {
        this.$router.push({ name: 'pm-policy-conditions', params: { policyId: id } })
      },
      ruleClicked(id) {
        this.$router.push({ name: 'pm-rules-rule', params: { ruleId: id } })
      },
      assignmentClicked() {
        this.$router.push({ name: 'pm-provisioning', params: { selectedAppliances: [this.appliance] } })
      },
    },
  }
</script>
