<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules">
    <u-autocomplete
      v-model="value"
      :items="items"
      :multiple="multipleSelection"
      :placeholder="$vuntangle.$t('value')"
      disable-lookup
      flat
      solo
      :outlined="false"
      :error-messages="errors"
    >
      <template v-if="multipleSelection" #selection="{ item, index }">
        <span v-if="index < othersIndex" class="mr-1">
          {{ ($t(item.text) || item) + (value.length - 1 > index ? ',' : '') }}
        </span>

        <span v-if="index === othersIndex">
          {{ $t('x_others', [value.length - othersIndex]) }}
        </span>
      </template>

      <template #item="{ item, attrs, on }">
        <v-list-item v-slot="{ active }" v-bind="attrs" dense class="px-2" :ripple="false" v-on="on">
          <v-list-item-action v-if="multipleSelection" class="my-0 mr-2">
            <v-checkbox :input-value="active" dense :ripple="false" />
          </v-list-item-action>
          <v-list-item-content>
            {{ $t(item.text) || item }}
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
    </u-autocomplete>
  </ValidationProvider>
</template>
<script>
  import mixin from './mixin'
  export default {
    mixins: [mixin],
    props: {
      values: { type: Array, default: () => undefined },
      multiple: { type: Boolean, default: false },
    },
    data() {
      return {
        othersIndex: 5,
      }
    },
    computed: {
      items: ({ values, conditionConfig }) => values || conditionConfig?.values,
      multipleSelection: ({ multiple, conditionConfig }) => multiple || conditionConfig?.multiple,

      value: {
        // if `multipleSelection` the value is already an array, otherwise it's a string or number
        get: ({ condition, multipleSelection }) => (multipleSelection ? condition.value : condition.value[0]),
        /**
         * Sets the value as a single array entry or multiple depending on the condition configuration
         * @param {Array} value - array of one or multiple values as sgtrings/numbers
         */
        set(value) {
          if (this.multipleSelection) this.condition.value = value
          else this.condition.value = [value]
        },
      },
    },
  }
</script>
