<template>
  <u-dialog-complex
    v-model="show"
    :width="600"
    :title="$t('reboot_options')"
    :show-progress="fetchProgress || pushProgress"
    :submit-label="$t('set_schedule')"
    @cancel="show = false"
    @submit="reboot('rebootAt')"
  >
    <div>
      <div>
        <span class="text--secondary"> {{ $t('current_server_datetime') }}:</span>
        {{ applianceDisplay }}
      </div>
      <div>
        <div class="text--secondary">{{ $t('reboot_scheduled') }}:</div>
        <u-btn class="my-4" :disabled="selectedRows.length <= 0" @click="deleteScheduledJobs">{{
          $t('reboot_delete')
        }}</u-btn>
        <u-grid
          id="reboots-grid"
          :no-data-message="$t('reboot_none_scheduled')"
          selection-type="multiAction"
          :selection.sync="selectedRows"
          :column-defs="columnDefs"
          :row-data="applianceScheduledReboot"
          style="height: 125px"
          toolbar="hidden"
          :enable-refresh="false"
        />
      </div>

      <br />

      <div class="text--secondary">{{ $t('reboot_picker_info') }}</div>

      <div class="d-flex flex-row">
        <u-select v-model="uiTimezone" class="mt-2 mr-2" :label="$t('reboot_timezone')" :items="timeZones"></u-select>

        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              class="mt-2 mr-2"
              outlined
              dense
              v-on="on"
              @click:append="dateMenu = true"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable :min="applianceDate" :show-current="applianceDate">
            <v-spacer></v-spacer>
            <u-btn @click="dateMenu = false">
              <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
                {{ $t('cancel') }}
              </span>
            </u-btn>
            <u-btn @click="$refs.dateMenu.save(date)">{{ $t('ok') }}</u-btn>
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="timeMenu"
          v-model="timeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="time"
              append-icon="mdi-clock-outline"
              readonly
              v-bind="attrs"
              class="mt-2"
              outlined
              dense
              v-on="on"
              @click:append="timeMenu = true"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timeMenu"
            v-model="time"
            no-title
            @click:minute="$refs.timeMenu.save(time)"
          ></v-time-picker>
        </v-menu>
      </div>
    </div>
    <template #extraActions>
      <u-btn
        color="aristaDarkGray white--text"
        @click="
          reboot('rebootNow')
          show = false
        "
        >{{ $t('reboot_now') }}</u-btn
      >
    </template>
  </u-dialog-complex>
</template>

<script>
  import api from '@/plugins/ut/ut-api'
  import vuntangle from '@/plugins/vuntangle'
  export default {
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
      appliance: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        applianceDate: null,
        date: null,
        dateMenu: false,
        time: '00:00',
        timeMenu: false,
        selectedRows: [],

        applianceDisplay: null,
        applianceTimezone: null,
        applianceDateTimeObj: null,
        applianceScheduledReboot: [],
        uiTimezone: '(UTC-08:00) Los Angeles',

        timeZones: [
          { text: this.$t('Pacific/Midway'), value: '(UTC-11:00) Midway' },
          { text: this.$t('Pacific/Niue'), value: '(UTC-11:00) Niue' },
          { text: this.$t('Pacific/Pago_Pago'), value: '(UTC-11:00) Pago Pago' },
          { text: this.$t('America/Adak'), value: '(UTC-10:00) Adak' },
          { text: this.$t('Pacific/Honolulu'), value: '(UTC-10:00) Honolulu' },
          { text: this.$t('Pacific/Johnston'), value: '(UTC-10:00) Johnston' },
          { text: this.$t('Pacific/Rarotonga'), value: '(UTC-10:00) Rarotonga' },
          { text: this.$t('Pacific/Tahiti'), value: '(UTC-10:00) Tahiti' },
          { text: this.$t('Pacific/Marquesas'), value: '(UTC-09:30) Marquesas' },
          { text: this.$t('America/Anchorage'), value: '(UTC-09:00) Anchorage' },
          { text: this.$t('Pacific/Gambier'), value: '(UTC-09:00) Gambier' },
          { text: this.$t('America/Juneau'), value: '(UTC-09:00) Juneau' },
          { text: this.$t('America/Nome'), value: '(UTC-09:00) Nome' },
          { text: this.$t('America/Sitka'), value: '(UTC-09:00) Sitka' },
          { text: this.$t('America/Yakutat'), value: '(UTC-09:00) Yakutat' },
          { text: this.$t('America/Dawson'), value: '(UTC-08:00) Dawson' },
          { text: this.$t('America/Los_Angeles'), value: '(UTC-08:00) Los Angeles' },
          { text: this.$t('America/Metlakatla'), value: '(UTC-08:00) Metlakatla' },
          { text: this.$t('Pacific/Pitcairn'), value: '(UTC-08:00) Pitcairn' },
          { text: this.$t('America/Santa_Isabel'), value: '(UTC-08:00) Santa Isabel' },
          { text: this.$t('America/Tijuana'), value: '(UTC-08:00) Tijuana' },
          { text: this.$t('America/Vancouver'), value: '(UTC-08:00) Vancouver' },
          { text: this.$t('America/Whitehorse'), value: '(UTC-08:00) Whitehorse' },
          { text: this.$t('America/Boise'), value: '(UTC-07:00) Boise' },
          { text: this.$t('America/Cambridge_Bay'), value: '(UTC-07:00) Cambridge Bay' },
          { text: this.$t('America/Chihuahua'), value: '(UTC-07:00) Chihuahua' },
          { text: this.$t('America/Creston'), value: '(UTC-07:00) Creston' },
          { text: this.$t('America/Dawson_Creek'), value: '(UTC-07:00) Dawson Creek' },
          { text: this.$t('America/Denver'), value: '(UTC-07:00) Denver' },
          { text: this.$t('America/Edmonton'), value: '(UTC-07:00) Edmonton' },
          { text: this.$t('America/Hermosillo'), value: '(UTC-07:00) Hermosillo' },
          { text: this.$t('America/Inuvik'), value: '(UTC-07:00) Inuvik' },
          { text: this.$t('America/Mazatlan'), value: '(UTC-07:00) Mazatlan' },
          { text: this.$t('America/Ojinaga'), value: '(UTC-07:00) Ojinaga' },
          { text: this.$t('America/Phoenix'), value: '(UTC-07:00) Phoenix' },
          { text: this.$t('America/Shiprock'), value: '(UTC-07:00) Shiprock' },
          { text: this.$t('America/Yellowknife'), value: '(UTC-07:00) Yellowknife' },
          { text: this.$t('America/Bahia_Banderas'), value: '(UTC-06:00) Bahia Banderas' },
          { text: this.$t('America/Belize'), value: '(UTC-06:00) Belize' },
          { text: this.$t('America/North_Dakota/Beulah'), value: '(UTC-06:00) Beulah' },
          { text: this.$t('America/Cancun'), value: '(UTC-06:00) Cancun' },
          { text: this.$t('America/North_Dakota/Center'), value: '(UTC-06:00) Center' },
          { text: this.$t('America/Chicago'), value: '(UTC-06:00) Chicago' },
          { text: this.$t('America/Costa_Rica'), value: '(UTC-06:00) Costa Rica' },
          { text: this.$t('Pacific/Easter'), value: '(UTC-06:00) Easter' },
          { text: this.$t('America/El_Salvador'), value: '(UTC-06:00) El Salvador' },
          { text: this.$t('Pacific/Galapagos'), value: '(UTC-06:00) Galapagos' },
          { text: this.$t('America/Guatemala'), value: '(UTC-06:00) Guatemala' },
          { text: this.$t('America/Indiana/Knox'), value: '(UTC-06:00) Knox' },
          { text: this.$t('America/Managua'), value: '(UTC-06:00) Managua' },
          { text: this.$t('America/Matamoros'), value: '(UTC-06:00) Matamoros' },
          { text: this.$t('America/Menominee'), value: '(UTC-06:00) Menominee' },
          { text: this.$t('America/Merida'), value: '(UTC-06:00) Merida' },
          { text: this.$t('America/Mexico_City'), value: '(UTC-06:00) Mexico City' },
          { text: this.$t('America/Monterrey'), value: '(UTC-06:00) Monterrey' },
          { text: this.$t('America/North_Dakota/New_Salem'), value: '(UTC-06:00) New Salem' },
          { text: this.$t('America/Rainy_River'), value: '(UTC-06:00) Rainy River' },
          { text: this.$t('America/Rankin_Inlet'), value: '(UTC-06:00) Rankin Inlet' },
          { text: this.$t('America/Regina'), value: '(UTC-06:00) Regina' },
          { text: this.$t('America/Resolute'), value: '(UTC-06:00) Resolute' },
          { text: this.$t('America/Swift_Current'), value: '(UTC-06:00) Swift Current' },
          { text: this.$t('America/Tegucigalpa'), value: '(UTC-06:00) Tegucigalpa' },
          { text: this.$t('America/Indiana/Tell_City'), value: '(UTC-06:00) Tell City' },
          { text: this.$t('America/Winnipeg'), value: '(UTC-06:00) Winnipeg' },
          { text: this.$t('America/Atikokan'), value: '(UTC-05:00) Atikokan' },
          { text: this.$t('America/Bogota'), value: '(UTC-05:00) Bogota' },
          { text: this.$t('America/Cayman'), value: '(UTC-05:00) Cayman' },
          { text: this.$t('America/Detroit'), value: '(UTC-05:00) Detroit' },
          { text: this.$t('America/Grand_Turk'), value: '(UTC-05:00) Grand Turk' },
          { text: this.$t('America/Guayaquil'), value: '(UTC-05:00) Guayaquil' },
          { text: this.$t('America/Havana'), value: '(UTC-05:00) Havana' },
          { text: this.$t('America/Indiana/Indianapolis'), value: '(UTC-05:00) Indianapolis' },
          { text: this.$t('America/Iqaluit'), value: '(UTC-05:00) Iqaluit' },
          { text: this.$t('America/Jamaica'), value: '(UTC-05:00) Jamaica' },
          { text: this.$t('America/Lima'), value: '(UTC-05:00) Lima' },
          { text: this.$t('America/Kentucky/Louisville'), value: '(UTC-05:00) Louisville' },
          { text: this.$t('America/Indiana/Marengo'), value: '(UTC-05:00) Marengo' },
          { text: this.$t('America/Kentucky/Monticello'), value: '(UTC-05:00) Monticello' },
          { text: this.$t('America/Montreal'), value: '(UTC-05:00) Montreal' },
          { text: this.$t('America/Nassau'), value: '(UTC-05:00) Nassau' },
          { text: this.$t('America/New_York'), value: '(UTC-05:00) New York' },
          { text: this.$t('America/Nipigon'), value: '(UTC-05:00) Nipigon' },
          { text: this.$t('America/Panama'), value: '(UTC-05:00) Panama' },
          { text: this.$t('America/Pangnirtung'), value: '(UTC-05:00) Pangnirtung' },
          { text: this.$t('America/Indiana/Petersburg'), value: '(UTC-05:00) Petersburg' },
          { text: this.$t('America/Port-au-Prince'), value: '(UTC-05:00) Port-au-Prince' },
          { text: this.$t('America/Thunder_Bay'), value: '(UTC-05:00) Thunder Bay' },
          { text: this.$t('America/Toronto'), value: '(UTC-05:00) Toronto' },
          { text: this.$t('America/Indiana/Vevay'), value: '(UTC-05:00) Vevay' },
          { text: this.$t('America/Indiana/Vincennes'), value: '(UTC-05:00) Vincennes' },
          { text: this.$t('America/Indiana/Winamac'), value: '(UTC-05:00) Winamac' },
          { text: this.$t('America/Caracas'), value: '(UTC-04:30) Caracas' },
          { text: this.$t('America/Anguilla'), value: '(UTC-04:00) Anguilla' },
          { text: this.$t('America/Antigua'), value: '(UTC-04:00) Antigua' },
          { text: this.$t('America/Aruba'), value: '(UTC-04:00) Aruba' },
          { text: this.$t('America/Asuncion'), value: '(UTC-04:00) Asuncion' },
          { text: this.$t('America/Barbados'), value: '(UTC-04:00) Barbados' },
          { text: this.$t('Atlantic/Bermuda'), value: '(UTC-04:00) Bermuda' },
          { text: this.$t('America/Blanc-Sablon'), value: '(UTC-04:00) Blanc-Sablon' },
          { text: this.$t('America/Boa_Vista'), value: '(UTC-04:00) Boa Vista' },
          { text: this.$t('America/Campo_Grande'), value: '(UTC-04:00) Campo Grande' },
          { text: this.$t('America/Cuiaba'), value: '(UTC-04:00) Cuiaba' },
          { text: this.$t('America/Curacao'), value: '(UTC-04:00) Curacao' },
          { text: this.$t('America/Dominica'), value: '(UTC-04:00) Dominica' },
          { text: this.$t('America/Eirunepe'), value: '(UTC-04:00) Eirunepe' },
          { text: this.$t('America/Glace_Bay'), value: '(UTC-04:00) Glace Bay' },
          { text: this.$t('America/Goose_Bay'), value: '(UTC-04:00) Goose Bay' },
          { text: this.$t('America/Grenada'), value: '(UTC-04:00) Grenada' },
          { text: this.$t('America/Guadeloupe'), value: '(UTC-04:00) Guadeloupe' },
          { text: this.$t('America/Guyana'), value: '(UTC-04:00) Guyana' },
          { text: this.$t('America/Halifax'), value: '(UTC-04:00) Halifax' },
          { text: this.$t('America/Kralendijk'), value: '(UTC-04:00) Kralendijk' },
          { text: this.$t('America/La_Paz'), value: '(UTC-04:00) La Paz' },
          { text: this.$t('America/Lower_Princes'), value: '(UTC-04:00) Lower Princes' },
          { text: this.$t('America/Manaus'), value: '(UTC-04:00) Manaus' },
          { text: this.$t('America/Marigot'), value: '(UTC-04:00) Marigot' },
          { text: this.$t('America/Martinique'), value: '(UTC-04:00) Martinique' },
          { text: this.$t('America/Moncton'), value: '(UTC-04:00) Moncton' },
          { text: this.$t('America/Montserrat'), value: '(UTC-04:00) Montserrat' },
          { text: this.$t('Antarctica/Palmer'), value: '(UTC-04:00) Palmer' },
          { text: this.$t('America/Port_of_Spain'), value: '(UTC-04:00) Port of Spain' },
          { text: this.$t('America/Porto_Velho'), value: '(UTC-04:00) Porto Velho' },
          { text: this.$t('America/Puerto_Rico'), value: '(UTC-04:00) Puerto Rico' },
          { text: this.$t('America/Rio_Branco'), value: '(UTC-04:00) Rio Branco' },
          { text: this.$t('America/Santiago'), value: '(UTC-04:00) Santiago' },
          { text: this.$t('America/Santo_Domingo'), value: '(UTC-04:00) Santo Domingo' },
          { text: this.$t('America/St_Barthelemy'), value: '(UTC-04:00) St. Barthelemy' },
          { text: this.$t('America/St_Kitts'), value: '(UTC-04:00) St. Kitts' },
          { text: this.$t('America/St_Lucia'), value: '(UTC-04:00) St. Lucia' },
          { text: this.$t('America/St_Thomas'), value: '(UTC-04:00) St. Thomas' },
          { text: this.$t('America/St_Vincent'), value: '(UTC-04:00) St. Vincent' },
          { text: this.$t('America/Thule'), value: '(UTC-04:00) Thule' },
          { text: this.$t('America/Tortola'), value: '(UTC-04:00) Tortola' },
          { text: this.$t('America/St_Johns'), value: '(UTC-03:30) St. Johns' },
          { text: this.$t('America/Araguaina'), value: '(UTC-03:00) Araguaina' },
          { text: this.$t('America/Bahia'), value: '(UTC-03:00) Bahia' },
          { text: this.$t('America/Belem'), value: '(UTC-03:00) Belem' },
          { text: this.$t('America/Argentina/Buenos_Aires'), value: '(UTC-03:00) Buenos Aires' },
          { text: this.$t('America/Argentina/Catamarca'), value: '(UTC-03:00) Catamarca' },
          { text: this.$t('America/Cayenne'), value: '(UTC-03:00) Cayenne' },
          { text: this.$t('America/Argentina/Cordoba'), value: '(UTC-03:00) Cordoba' },
          { text: this.$t('America/Fortaleza'), value: '(UTC-03:00) Fortaleza' },
          { text: this.$t('America/Godthab'), value: '(UTC-03:00) Godthab' },
          { text: this.$t('America/Argentina/Jujuy'), value: '(UTC-03:00) Jujuy' },
          { text: this.$t('America/Argentina/La_Rioja'), value: '(UTC-03:00) La Rioja' },
          { text: this.$t('America/Maceio'), value: '(UTC-03:00) Maceio' },
          { text: this.$t('America/Argentina/Mendoza'), value: '(UTC-03:00) Mendoza' },
          { text: this.$t('America/Miquelon'), value: '(UTC-03:00) Miquelon' },
          { text: this.$t('America/Montevideo'), value: '(UTC-03:00) Montevideo' },
          { text: this.$t('America/Paramaribo'), value: '(UTC-03:00) Paramaribo' },
          { text: this.$t('America/Recife'), value: '(UTC-03:00) Recife' },
          { text: this.$t('America/Argentina/Rio_Gallegos'), value: '(UTC-03:00) Rio Gallegos' },
          { text: this.$t('Antarctica/Rothera'), value: '(UTC-03:00) Rothera' },
          { text: this.$t('America/Argentina/Salta'), value: '(UTC-03:00) Salta' },
          { text: this.$t('America/Argentina/San_Juan'), value: '(UTC-03:00) San Juan' },
          { text: this.$t('America/Argentina/San_Luis'), value: '(UTC-03:00) San Luis' },
          { text: this.$t('America/Santarem'), value: '(UTC-03:00) Santarem' },
          { text: this.$t('America/Sao_Paulo'), value: '(UTC-03:00) Sao Paulo' },
          { text: this.$t('Atlantic/Stanley'), value: '(UTC-03:00) Stanley' },
          { text: this.$t('America/Argentina/Tucuman'), value: '(UTC-03:00) Tucuman' },
          { text: this.$t('America/Argentina/Ushuaia'), value: '(UTC-03:00) Ushuaia' },
          { text: this.$t('America/Noronha'), value: '(UTC-02:00) Noronha' },
          { text: this.$t('Atlantic/South_Georgia'), value: '(UTC-02:00) South Georgia' },
          { text: this.$t('Atlantic/Azores'), value: '(UTC-01:00) Azores' },
          { text: this.$t('Atlantic/Cape_Verde'), value: '(UTC-01:00) Cape Verde' },
          { text: this.$t('America/Scoresbysund'), value: '(UTC-01:00) Scoresbysund' },
          { text: this.$t('Africa/Abidjan'), value: '(UTC+00:00) Abidjan' },
          { text: this.$t('Africa/Accra'), value: '(UTC+00:00) Accra' },
          { text: this.$t('Africa/Bamako'), value: '(UTC+00:00) Bamako' },
          { text: this.$t('Africa/Banjul'), value: '(UTC+00:00) Banjul' },
          { text: this.$t('Africa/Bissau'), value: '(UTC+00:00) Bissau' },
          { text: this.$t('Atlantic/Canary'), value: '(UTC+00:00) Canary' },
          { text: this.$t('Africa/Casablanca'), value: '(UTC+00:00) Casablanca' },
          { text: this.$t('Africa/Conakry'), value: '(UTC+00:00) Conakry' },
          { text: this.$t('Africa/Dakar'), value: '(UTC+00:00) Dakar' },
          { text: this.$t('America/Danmarkshavn'), value: '(UTC+00:00) Danmarkshavn' },
          { text: this.$t('Europe/Dublin'), value: '(UTC+00:00) Dublin' },
          { text: this.$t('Africa/El_Aaiun'), value: '(UTC+00:00) El Aaiun' },
          { text: this.$t('Atlantic/Faroe'), value: '(UTC+00:00) Faroe' },
          { text: this.$t('Africa/Freetown'), value: '(UTC+00:00) Freetown' },
          { text: this.$t('Europe/Guernsey'), value: '(UTC+00:00) Guernsey' },
          { text: this.$t('Europe/Isle_of_Man'), value: '(UTC+00:00) Isle of Man' },
          { text: this.$t('Europe/Jersey'), value: '(UTC+00:00) Jersey' },
          { text: this.$t('Europe/Lisbon'), value: '(UTC+00:00) Lisbon' },
          { text: this.$t('Africa/Lome'), value: '(UTC+00:00) Lome' },
          { text: this.$t('Europe/London'), value: '(UTC+00:00) London' },
          { text: this.$t('Atlantic/Madeira'), value: '(UTC+00:00) Madeira' },
          { text: this.$t('Africa/Monrovia'), value: '(UTC+00:00) Monrovia' },
          { text: this.$t('Africa/Nouakchott'), value: '(UTC+00:00) Nouakchott' },
          { text: this.$t('Africa/Ouagadougou'), value: '(UTC+00:00) Ouagadougou' },
          { text: this.$t('Atlantic/Reykjavik'), value: '(UTC+00:00) Reykjavik' },
          { text: this.$t('Africa/Sao_Tome'), value: '(UTC+00:00) Sao Tome' },
          { text: this.$t('Atlantic/St_Helena'), value: '(UTC+00:00) St. Helena' },
          { text: this.$t('UTC'), value: '(UTC+00:00) UTC' },
          { text: this.$t('Africa/Algiers'), value: '(UTC+01:00) Algiers' },
          { text: this.$t('Europe/Amsterdam'), value: '(UTC+01:00) Amsterdam' },
          { text: this.$t('Europe/Andorra'), value: '(UTC+01:00) Andorra' },
          { text: this.$t('Africa/Bangui'), value: '(UTC+01:00) Bangui' },
          { text: this.$t('Europe/Belgrade'), value: '(UTC+01:00) Belgrade' },
          { text: this.$t('Europe/Berlin'), value: '(UTC+01:00) Berlin' },
          { text: this.$t('Europe/Bratislava'), value: '(UTC+01:00) Bratislava' },
          { text: this.$t('Africa/Brazzaville'), value: '(UTC+01:00) Brazzaville' },
          { text: this.$t('Europe/Brussels'), value: '(UTC+01:00) Brussels' },
          { text: this.$t('Europe/Budapest'), value: '(UTC+01:00) Budapest' },
          { text: this.$t('Europe/Busingen'), value: '(UTC+01:00) Busingen' },
          { text: this.$t('Africa/Ceuta'), value: '(UTC+01:00) Ceuta' },
          { text: this.$t('Europe/Copenhagen'), value: '(UTC+01:00) Copenhagen' },
          { text: this.$t('Africa/Douala'), value: '(UTC+01:00) Douala' },
          { text: this.$t('Europe/Gibraltar'), value: '(UTC+01:00) Gibraltar' },
          { text: this.$t('Africa/Kinshasa'), value: '(UTC+01:00) Kinshasa' },
          { text: this.$t('Africa/Lagos'), value: '(UTC+01:00) Lagos' },
          { text: this.$t('Africa/Libreville'), value: '(UTC+01:00) Libreville' },
          { text: this.$t('Europe/Ljubljana'), value: '(UTC+01:00) Ljubljana' },
          { text: this.$t('Arctic/Longyearbyen'), value: '(UTC+01:00) Longyearbyen' },
          { text: this.$t('Africa/Luanda'), value: '(UTC+01:00) Luanda' },
          { text: this.$t('Europe/Luxembourg'), value: '(UTC+01:00) Luxembourg' },
          { text: this.$t('Europe/Madrid'), value: '(UTC+01:00) Madrid' },
          { text: this.$t('Africa/Malabo'), value: '(UTC+01:00) Malabo' },
          { text: this.$t('Europe/Malta'), value: '(UTC+01:00) Malta' },
          { text: this.$t('Europe/Monaco'), value: '(UTC+01:00) Monaco' },
          { text: this.$t('Africa/Ndjamena'), value: '(UTC+01:00) Ndjamena' },
          { text: this.$t('Africa/Niamey'), value: '(UTC+01:00) Niamey' },
          { text: this.$t('Europe/Oslo'), value: '(UTC+01:00) Oslo' },
          { text: this.$t('Europe/Paris'), value: '(UTC+01:00) Paris' },
          { text: this.$t('Europe/Podgorica'), value: '(UTC+01:00) Podgorica' },
          { text: this.$t('Africa/Porto-Novo'), value: '(UTC+01:00) Porto-Novo' },
          { text: this.$t('Europe/Prague'), value: '(UTC+01:00) Prague' },
          { text: this.$t('Europe/Rome'), value: '(UTC+01:00) Rome' },
          { text: this.$t('Europe/San_Marino'), value: '(UTC+01:00) San Marino' },
          { text: this.$t('Europe/Sarajevo'), value: '(UTC+01:00) Sarajevo' },
          { text: this.$t('Europe/Skopje'), value: '(UTC+01:00) Skopje' },
          { text: this.$t('Europe/Stockholm'), value: '(UTC+01:00) Stockholm' },
          { text: this.$t('Europe/Tirane'), value: '(UTC+01:00) Tirane' },
          { text: this.$t('Africa/Tripoli'), value: '(UTC+01:00) Tripoli' },
          { text: this.$t('Africa/Tunis'), value: '(UTC+01:00) Tunis' },
          { text: this.$t('Europe/Vaduz'), value: '(UTC+01:00) Vaduz' },
          { text: this.$t('Europe/Vatican'), value: '(UTC+01:00) Vatican' },
          { text: this.$t('Europe/Vienna'), value: '(UTC+01:00) Vienna' },
          { text: this.$t('Europe/Warsaw'), value: '(UTC+01:00) Warsaw' },
          { text: this.$t('Africa/Windhoek'), value: '(UTC+01:00) Windhoek' },
          { text: this.$t('Europe/Zagreb'), value: '(UTC+01:00) Zagreb' },
          { text: this.$t('Europe/Zurich'), value: '(UTC+01:00) Zurich' },
          { text: this.$t('Europe/Athens'), value: '(UTC+02:00) Athens' },
          { text: this.$t('Asia/Beirut'), value: '(UTC+02:00) Beirut' },
          { text: this.$t('Africa/Blantyre'), value: '(UTC+02:00) Blantyre' },
          { text: this.$t('Europe/Bucharest'), value: '(UTC+02:00) Bucharest' },
          { text: this.$t('Africa/Bujumbura'), value: '(UTC+02:00) Bujumbura' },
          { text: this.$t('Africa/Cairo'), value: '(UTC+02:00) Cairo' },
          { text: this.$t('Europe/Chisinau'), value: '(UTC+02:00) Chisinau' },
          { text: this.$t('Asia/Damascus'), value: '(UTC+02:00) Damascus' },
          { text: this.$t('Africa/Gaborone'), value: '(UTC+02:00) Gaborone' },
          { text: this.$t('Asia/Gaza'), value: '(UTC+02:00) Gaza' },
          { text: this.$t('Africa/Harare'), value: '(UTC+02:00) Harare' },
          { text: this.$t('Asia/Hebron'), value: '(UTC+02:00) Hebron' },
          { text: this.$t('Europe/Helsinki'), value: '(UTC+02:00) Helsinki' },
          { text: this.$t('Europe/Istanbul'), value: '(UTC+02:00) Istanbul' },
          { text: this.$t('Asia/Jerusalem'), value: '(UTC+02:00) Jerusalem' },
          { text: this.$t('Africa/Johannesburg'), value: '(UTC+02:00) Johannesburg' },
          { text: this.$t('Europe/Kiev'), value: '(UTC+02:00) Kiev' },
          { text: this.$t('Africa/Kigali'), value: '(UTC+02:00) Kigali' },
          { text: this.$t('Africa/Lubumbashi'), value: '(UTC+02:00) Lubumbashi' },
          { text: this.$t('Africa/Lusaka'), value: '(UTC+02:00) Lusaka' },
          { text: this.$t('Africa/Maputo'), value: '(UTC+02:00) Maputo' },
          { text: this.$t('Europe/Mariehamn'), value: '(UTC+02:00) Mariehamn' },
          { text: this.$t('Africa/Maseru'), value: '(UTC+02:00) Maseru' },
          { text: this.$t('Africa/Mbabane'), value: '(UTC+02:00) Mbabane' },
          { text: this.$t('Asia/Nicosia'), value: '(UTC+02:00) Nicosia' },
          { text: this.$t('Europe/Riga'), value: '(UTC+02:00) Riga' },
          { text: this.$t('Europe/Simferopol'), value: '(UTC+02:00) Simferopol' },
          { text: this.$t('Europe/Sofia'), value: '(UTC+02:00) Sofia' },
          { text: this.$t('Europe/Tallinn'), value: '(UTC+02:00) Tallinn' },
          { text: this.$t('Europe/Uzhgorod'), value: '(UTC+02:00) Uzhgorod' },
          { text: this.$t('Europe/Vilnius'), value: '(UTC+02:00) Vilnius' },
          { text: this.$t('Europe/Zaporozhye'), value: '(UTC+02:00) Zaporozhye' },
          { text: this.$t('Africa/Addis_Ababa'), value: '(UTC+03:00) Addis Ababa' },
          { text: this.$t('Asia/Aden'), value: '(UTC+03:00) Aden' },
          { text: this.$t('Asia/Amman'), value: '(UTC+03:00) Amman' },
          { text: this.$t('Indian/Antananarivo'), value: '(UTC+03:00) Antananarivo' },
          { text: this.$t('Africa/Asmara'), value: '(UTC+03:00) Asmara' },
          { text: this.$t('Asia/Baghdad'), value: '(UTC+03:00) Baghdad' },
          { text: this.$t('Asia/Bahrain'), value: '(UTC+03:00) Bahrain' },
          { text: this.$t('Indian/Comoro'), value: '(UTC+03:00) Comoro' },
          { text: this.$t('Africa/Dar_es_Salaam'), value: '(UTC+03:00) Dar es Salaam' },
          { text: this.$t('Africa/Djibouti'), value: '(UTC+03:00) Djibouti' },
          { text: this.$t('Africa/Juba'), value: '(UTC+03:00) Juba' },
          { text: this.$t('Europe/Kaliningrad'), value: '(UTC+03:00) Kaliningrad' },
          { text: this.$t('Africa/Kampala'), value: '(UTC+03:00) Kampala' },
          { text: this.$t('Africa/Khartoum'), value: '(UTC+03:00) Khartoum' },
          { text: this.$t('Asia/Kuwait'), value: '(UTC+03:00) Kuwait' },
          { text: this.$t('Indian/Mayotte'), value: '(UTC+03:00) Mayotte' },
          { text: this.$t('Europe/Minsk'), value: '(UTC+03:00) Minsk' },
          { text: this.$t('Africa/Mogadishu'), value: '(UTC+03:00) Mogadishu' },
          { text: this.$t('Europe/Moscow'), value: '(UTC+03:00) Moscow' },
          { text: this.$t('Africa/Nairobi'), value: '(UTC+03:00) Nairobi' },
          { text: this.$t('Asia/Qatar'), value: '(UTC+03:00) Qatar' },
          { text: this.$t('Asia/Riyadh'), value: '(UTC+03:00) Riyadh' },
          { text: this.$t('Antarctica/Syowa'), value: '(UTC+03:00) Syowa' },
          { text: this.$t('Asia/Tehran'), value: '(UTC+03:30) Tehran' },
          { text: this.$t('Asia/Baku'), value: '(UTC+04:00) Baku' },
          { text: this.$t('Asia/Dubai'), value: '(UTC+04:00) Dubai' },
          { text: this.$t('Indian/Mahe'), value: '(UTC+04:00) Mahe' },
          { text: this.$t('Indian/Mauritius'), value: '(UTC+04:00) Mauritius' },
          { text: this.$t('Asia/Muscat'), value: '(UTC+04:00) Muscat' },
          { text: this.$t('Indian/Reunion'), value: '(UTC+04:00) Reunion' },
          { text: this.$t('Europe/Samara'), value: '(UTC+04:00) Samara' },
          { text: this.$t('Asia/Tbilisi'), value: '(UTC+04:00) Tbilisi' },
          { text: this.$t('Europe/Volgograd'), value: '(UTC+04:00) Volgograd' },
          { text: this.$t('Asia/Yerevan'), value: '(UTC+04:00) Yerevan' },
          { text: this.$t('Asia/Kabul'), value: '(UTC+04:30) Kabul' },
          { text: this.$t('Asia/Aqtau'), value: '(UTC+05:00) Aqtau' },
          { text: this.$t('Asia/Aqtobe'), value: '(UTC+05:00) Aqtobe' },
          { text: this.$t('Asia/Ashgabat'), value: '(UTC+05:00) Ashgabat' },
          { text: this.$t('Asia/Dushanbe'), value: '(UTC+05:00) Dushanbe' },
          { text: this.$t('Asia/Karachi'), value: '(UTC+05:00) Karachi' },
          { text: this.$t('Indian/Kerguelen'), value: '(UTC+05:00) Kerguelen' },
          { text: this.$t('Indian/Maldives'), value: '(UTC+05:00) Maldives' },
          { text: this.$t('Antarctica/Mawson'), value: '(UTC+05:00) Mawson' },
          { text: this.$t('Asia/Oral'), value: '(UTC+05:00) Oral' },
          { text: this.$t('Asia/Samarkand'), value: '(UTC+05:00) Samarkand' },
          { text: this.$t('Asia/Tashkent'), value: '(UTC+05:00) Tashkent' },
          { text: this.$t('Asia/Colombo'), value: '(UTC+05:30) Colombo' },
          { text: this.$t('Asia/Kolkata'), value: '(UTC+05:30) Kolkata' },
          { text: this.$t('Asia/Kathmandu'), value: '(UTC+05:45) Kathmandu' },
          { text: this.$t('Asia/Almaty'), value: '(UTC+06:00) Almaty' },
          { text: this.$t('Asia/Bishkek'), value: '(UTC+06:00) Bishkek' },
          { text: this.$t('Indian/Chagos'), value: '(UTC+06:00) Chagos' },
          { text: this.$t('Asia/Dhaka'), value: '(UTC+06:00) Dhaka' },
          { text: this.$t('Asia/Qyzylorda'), value: '(UTC+06:00) Qyzylorda' },
          { text: this.$t('Asia/Thimphu'), value: '(UTC+06:00) Thimphu' },
          { text: this.$t('Antarctica/Vostok'), value: '(UTC+06:00) Vostok' },
          { text: this.$t('Asia/Yekaterinburg'), value: '(UTC+06:00) Yekaterinburg' },
          { text: this.$t('Indian/Cocos'), value: '(UTC+06:30) Cocos' },
          { text: this.$t('Asia/Rangoon'), value: '(UTC+06:30) Rangoon' },
          { text: this.$t('Asia/Bangkok'), value: '(UTC+07:00) Bangkok' },
          { text: this.$t('Indian/Christmas'), value: '(UTC+07:00) Christmas' },
          { text: this.$t('Antarctica/Davis'), value: '(UTC+07:00) Davis' },
          { text: this.$t('Asia/Ho_Chi_Minh'), value: '(UTC+07:00) Ho Chi Minh' },
          { text: this.$t('Asia/Hovd'), value: '(UTC+07:00) Hovd' },
          { text: this.$t('Asia/Jakarta'), value: '(UTC+07:00) Jakarta' },
          { text: this.$t('Asia/Novokuznetsk'), value: '(UTC+07:00) Novokuznetsk' },
          { text: this.$t('Asia/Novosibirsk'), value: '(UTC+07:00) Novosibirsk' },
          { text: this.$t('Asia/Omsk'), value: '(UTC+07:00) Omsk' },
          { text: this.$t('Asia/Phnom_Penh'), value: '(UTC+07:00) Phnom Penh' },
          { text: this.$t('Asia/Pontianak'), value: '(UTC+07:00) Pontianak' },
          { text: this.$t('Asia/Vientiane'), value: '(UTC+07:00) Vientiane' },
          { text: this.$t('Asia/Brunei'), value: '(UTC+08:00) Brunei' },
          { text: this.$t('Antarctica/Casey'), value: '(UTC+08:00) Casey' },
          { text: this.$t('Asia/Choibalsan'), value: '(UTC+08:00) Choibalsan' },
          { text: this.$t('Asia/Chongqing'), value: '(UTC+08:00) Chongqing' },
          { text: this.$t('Asia/Harbin'), value: '(UTC+08:00) Harbin' },
          { text: this.$t('Asia/Hong_Kong'), value: '(UTC+08:00) Hong Kong' },
          { text: this.$t('Asia/Kashgar'), value: '(UTC+08:00) Kashgar' },
          { text: this.$t('Asia/Krasnoyarsk'), value: '(UTC+08:00) Krasnoyarsk' },
          { text: this.$t('Asia/Kuala_Lumpur'), value: '(UTC+08:00) Kuala Lumpur' },
          { text: this.$t('Asia/Kuching'), value: '(UTC+08:00) Kuching' },
          { text: this.$t('Asia/Macau'), value: '(UTC+08:00) Macau' },
          { text: this.$t('Asia/Makassar'), value: '(UTC+08:00) Makassar' },
          { text: this.$t('Asia/Manila'), value: '(UTC+08:00) Manila' },
          { text: this.$t('Australia/Perth'), value: '(UTC+08:00) Perth' },
          { text: this.$t('Asia/Shanghai'), value: '(UTC+08:00) Shanghai' },
          { text: this.$t('Asia/Singapore'), value: '(UTC+08:00) Singapore' },
          { text: this.$t('Asia/Taipei'), value: '(UTC+08:00) Taipei' },
          { text: this.$t('Asia/Ulaanbaatar'), value: '(UTC+08:00) Ulaanbaatar' },
          { text: this.$t('Asia/Urumqi'), value: '(UTC+08:00) Urumqi' },
          { text: this.$t('Australia/Eucla'), value: '(UTC+08:45) Eucla' },
          { text: this.$t('Asia/Dili'), value: '(UTC+09:00) Dili' },
          { text: this.$t('Asia/Irkutsk'), value: '(UTC+09:00) Irkutsk' },
          { text: this.$t('Asia/Jayapura'), value: '(UTC+09:00) Jayapura' },
          { text: this.$t('Pacific/Palau'), value: '(UTC+09:00) Palau' },
          { text: this.$t('Asia/Pyongyang'), value: '(UTC+09:00) Pyongyang' },
          { text: this.$t('Asia/Seoul'), value: '(UTC+09:00) Seoul' },
          { text: this.$t('Asia/Tokyo'), value: '(UTC+09:00) Tokyo' },
          { text: this.$t('Australia/Adelaide'), value: '(UTC+09:30) Adelaide' },
          { text: this.$t('Australia/Broken_Hill'), value: '(UTC+09:30) Broken Hill' },
          { text: this.$t('Australia/Darwin'), value: '(UTC+09:30) Darwin' },
          { text: this.$t('Australia/Brisbane'), value: '(UTC+10:00) Brisbane' },
          { text: this.$t('Pacific/Chuuk'), value: '(UTC+10:00) Chuuk' },
          { text: this.$t('Australia/Currie'), value: '(UTC+10:00) Currie' },
          { text: this.$t('Antarctica/DumontDUrville'), value: '(UTC+10:00) DumontDUrville' },
          { text: this.$t('Pacific/Guam'), value: '(UTC+10:00) Guam' },
          { text: this.$t('Australia/Hobart'), value: '(UTC+10:00) Hobart' },
          { text: this.$t('Asia/Khandyga'), value: '(UTC+10:00) Khandyga' },
          { text: this.$t('Australia/Lindeman'), value: '(UTC+10:00) Lindeman' },
          { text: this.$t('Australia/Melbourne'), value: '(UTC+10:00) Melbourne' },
          { text: this.$t('Pacific/Port_Moresby'), value: '(UTC+10:00) Port Moresby' },
          { text: this.$t('Pacific/Saipan'), value: '(UTC+10:00) Saipan' },
          { text: this.$t('Australia/Sydney'), value: '(UTC+10:00) Sydney' },
          { text: this.$t('Asia/Yakutsk'), value: '(UTC+10:00) Yakutsk' },
          { text: this.$t('Australia/Lord_Howe'), value: '(UTC+10:30) Lord Howe' },
          { text: this.$t('Pacific/Efate'), value: '(UTC+11:00) Efate' },
          { text: this.$t('Pacific/Guadalcanal'), value: '(UTC+11:00) Guadalcanal' },
          { text: this.$t('Pacific/Kosrae'), value: '(UTC+11:00) Kosrae' },
          { text: this.$t('Antarctica/Macquarie'), value: '(UTC+11:00) Macquarie' },
          { text: this.$t('Pacific/Noumea'), value: '(UTC+11:00) Noumea' },
          { text: this.$t('Pacific/Pohnpei'), value: '(UTC+11:00) Pohnpei' },
          { text: this.$t('Asia/Sakhalin'), value: '(UTC+11:00) Sakhalin' },
          { text: this.$t('Asia/Ust-Nera'), value: '(UTC+11:00) Ust-Nera' },
          { text: this.$t('Asia/Vladivostok'), value: '(UTC+11:00) Vladivostok' },
          { text: this.$t('Pacific/Norfolk'), value: '(UTC+11:30) Norfolk' },
          { text: this.$t('Asia/Anadyr'), value: '(UTC+12:00) Anadyr' },
          { text: this.$t('Pacific/Auckland'), value: '(UTC+12:00) Auckland' },
          { text: this.$t('Pacific/Fiji'), value: '(UTC+12:00) Fiji' },
          { text: this.$t('Pacific/Funafuti'), value: '(UTC+12:00) Funafuti' },
          { text: this.$t('Asia/Kamchatka'), value: '(UTC+12:00) Kamchatka' },
          { text: this.$t('Pacific/Kwajalein'), value: '(UTC+12:00) Kwajalein' },
          { text: this.$t('Asia/Magadan'), value: '(UTC+12:00) Magadan' },
          { text: this.$t('Pacific/Majuro'), value: '(UTC+12:00) Majuro' },
          { text: this.$t('Antarctica/McMurdo'), value: '(UTC+12:00) McMurdo' },
          { text: this.$t('Pacific/Nauru'), value: '(UTC+12:00) Nauru' },
          { text: this.$t('Antarctica/South_Pole'), value: '(UTC+12:00) South Pole' },
          { text: this.$t('Pacific/Tarawa'), value: '(UTC+12:00) Tarawa' },
          { text: this.$t('Pacific/Wake'), value: '(UTC+12:00) Wake' },
          { text: this.$t('Pacific/Wallis'), value: '(UTC+12:00) Wallis' },
          { text: this.$t('Pacific/Chatham'), value: '(UTC+12:45) Chatham' },
          { text: this.$t('Pacific/Apia'), value: '(UTC+13:00) Apia' },
          { text: this.$t('Pacific/Enderbury'), value: '(UTC+13:00) Enderbury' },
          { text: this.$t('Pacific/Fakaofo'), value: '(UTC+13:00) Fakaofo' },
          { text: this.$t('Pacific/Tongatapu'), value: '(UTC+13:00) Tongatapu' },
          { text: this.$t('Pacific/Kiritimati'), value: '(UTC+14:00) Kiritimati' },
        ],

        fetchProgress: false, // used for fetching appliance datetime/schedule
        pushProgress: false, // used upon save
      }
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: this.$t('job_id'),
            field: 'jobId',
            hide: true,
          },
          {
            headerName: this.$t('reboot_time'),
            field: 'scheduledTime',
            valueGetter: ({ data }) => data.scheduledTime + ' ' + this.applianceTimezone,
          },
        ]
      },
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
    watch: {
      show(val) {
        if (val) {
          // fetch appliance reboot settings on dialog display
          this.prepareRebootData()
        }
      },
    },
    methods: {
      onCancel() {
        this.show = false
      },

      async prepareRebootData() {
        this.fetchProgress = true
        const dateTimeResponse = await api.cloud('Untangle_CommandCenter', 'GetApplianceDateTime', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })

        if (dateTimeResponse.success) {
          const applianceDateTimeObj = new Date(dateTimeResponse.data.applianceDateTime)
          this.applianceDisplay = dateTimeResponse.data.applianceDisplay
          this.applianceDateTimeObj = applianceDateTimeObj
          this.applianceDate = dateTimeResponse.data.applianceDate
          this.date = dateTimeResponse.data.applianceDate
          this.applianceTimezone = dateTimeResponse.data.timezone

          const queueResponse = await api.cloud('Untangle_CommandCenter', 'GetApplianceAtQueue', {
            uid: this.appliance.Uid,
            paramOrder: 'uid',
          })
          if (queueResponse.success) {
            this.applianceScheduledReboot = queueResponse.data || []
          }
        }
        this.fetchProgress = false
      },

      /**
       * @param {String} rebootType - The type of reboot ('rebootNow' or 'rebootAt')
       * @var {Bool} rebootTimeDuplicated - tracks if the time scheduled for a reboot has already been made
       */
      async reboot(rebootType) {
        if (rebootType === 'rebootAt' && (!this.date || !this.time)) {
          return
        }
        // if reboot has already been scheduled, send toast to delete that first to change reboot time and return
        if (this.applianceScheduledReboot.length > 0) {
          vuntangle.toast.add(this.$t('single_reboot_time_restriction'), 'error')
          return
        }
        // The delay is in milliseconds in receiving date/time data from the appliance
        const delay = 1000
        // Obtain the appliance timezone offset
        const applianceUtcDeltaMinis = this.applianceDateTimeObj.getTimezoneOffset()
        // Obtain the etm timezone offset
        const etmUtcDeltaMinis = new Date().getTimezoneOffset()
        // Get the provided time in milliseconds
        let givenTimeMillis = new Date(this.date + ' ' + this.time + ':00').getTime()
        givenTimeMillis += givenTimeMillis % delay > 0 ? delay - (givenTimeMillis % delay) : 0
        const arr = this.uiTimezone.toString().split('UTC')[1].split(':')
        // Get the provided timezone hours difference
        const givenTimezoneHoursDiff = parseInt(arr[0])
        // Get the provided timezone minutes difference
        const givenTimezoneMinisDiff = parseInt(arr[1].split(')')[0])
        // Get the provided overall time diff in minutes
        const givenTimeDiffInMinis = givenTimezoneHoursDiff * 60 + givenTimezoneMinisDiff

        // Get the timezone difference between etm and provided appliance
        const timeDiffApplianceWithEtmAtGivenTimezoneInMinis =
          applianceUtcDeltaMinis - givenTimeDiffInMinis - 2 * etmUtcDeltaMinis
        givenTimeMillis += timeDiffApplianceWithEtmAtGivenTimezoneInMinis * 60 * 1000
        const finalDateTimeArr = new Date(givenTimeMillis).toLocaleString().split(' ')
        // AM time
        let meridiem = 'AM'
        if (finalDateTimeArr.length > 2) {
          meridiem = finalDateTimeArr[2]
        }
        const curDateArr = finalDateTimeArr[0].split('/')
        // final date and time to set
        const finalDateToSet = curDateArr[2].toString().split(',')[0] + '-' + curDateArr[0] + '-' + curDateArr[1]
        let finalTimeToSet = 0
        if (meridiem === 'PM') {
          const timeArr = finalDateTimeArr[1].split(':')
          const hour = parseInt(timeArr[0]) + 12 === 24 ? '00' : parseInt(timeArr[0]) + 12
          finalTimeToSet = hour.toString() + ':' + timeArr[1] + ':' + timeArr[2]
        } else {
          finalTimeToSet = finalDateTimeArr[1]
        }
        // check if the reboot time has already been scheduled
        const dateTime = `${finalDateToSet.toString()} ${finalTimeToSet.toString()}`
        let rebootTimeDuplicated = false
        if (this.applianceScheduledReboot.length !== 0) {
          this.applianceScheduledReboot.forEach(queueItem => {
            if (rebootType === 'rebootAt' && dateTime === queueItem.rebootTime) {
              rebootTimeDuplicated = true
            }
          })
        }
        // if reboot has already been scheduled, send toast and return
        if (rebootTimeDuplicated) {
          vuntangle.toast.add(this.$t('duplicate_reboot_time'), 'error')
          return
        }

        // check if the reboot time has passed already
        const rebootDateObject = new Date(dateTime)
        // if reboot time has already passed, send toast and return
        if (rebootType === 'rebootAt' && rebootDateObject <= this.applianceDateTimeObj) {
          vuntangle.toast.add(this.$t('past_reboot_time'), 'error')
          return
        }

        // TODO - add extra confirmation
        this.pushProgress = true
        const response = await api.cloud('Untangle_CommandCenter', 'RebootAppliance', {
          uid: this.appliance.Uid,
          rebootType,
          rebootDate: rebootType === 'rebootAt' ? finalDateToSet.toString() : null,
          rebootTime: rebootType === 'rebootAt' ? finalTimeToSet.toString() : null,
          paramOrder: 'uid rebootType rebootDate rebootTime',
        })
        this.pushProgress = false
        // response.data will be false if the appliance is offline
        if (response.success && response.data) {
          if (rebootType === 'rebootAt') {
            const dateStr = finalDateToSet.toString() + ' at ' + finalTimeToSet.toString()
            vuntangle.toast.add(this.$t('reboot_future_successful') + ': ' + dateStr)
          } else {
            vuntangle.toast.add(this.$t('reboot_now_successful'))
          }
        } else {
          vuntangle.toast.add(this.$t('command_failure'), 'error')
        }
        if (rebootType !== 'rebootNow') this.prepareRebootData()
      },
      /**
       * Back end call to signal to appliance to delete all selected at jobs
       * parameter sent to back end is jobIds.
       */
      async deleteScheduledJobs() {
        const jobList = this.selectedRows.map(row => row.jobId)
        this.pushProgress = true
        const response = await api.cloud('Untangle_CommandCenter', 'DeleteAtQueueEntries', {
          uid: this.appliance.Uid,
          jobIds: jobList,
          paramOrder: 'uid jobIds',
        })
        if (response.success) {
          this.applianceScheduledReboot = response.data || []
        } else {
          vuntangle.toast.add(this.$t('command_failure'), 'error')
        }
        this.pushProgress = false
        this.selectedRows = []
      },
    },
  }
</script>
