<template>
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 ma-4">
    <grid-wrapper
      ref="wrapper"
      :headline="$t('rules')"
      :subtitle="$t('rules_description')"
      :selection.sync="selection"
      :show-delete="rules.length > 0"
    >
      <template #actions>
        <v-menu bottom left offset-y transition="slide-y-transition">
          <template #activator="{ on, attrs }">
            <v-btn depressed color="primary" class="text-capitalize" v-bind="attrs" v-on="on">
              <v-icon small class="mr-2">mdi-plus</v-icon>
              {{ $t('create_new') }}
            </v-btn>
          </template>
          <v-list class="list-dense pa-2" nav dense width="240">
            <div v-for="(items, key) in menuItems" :key="key">
              <v-subheader v-if="key !== 'none'">{{ $t(key) }}</v-subheader>
              <v-list-item-group :class="key !== 'none' ? 'pl-4' : ''">
                <v-list-item v-for="item in items" :key="item.key" dense @click="onCreate({ type: item.type })">
                  <v-list-item-title>
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-list>
        </v-menu>
      </template>
      <template #default="{ selectionType }">
        <u-grid
          id="all-rules"
          row-node-id="id"
          :selection-type="selectionType"
          :no-data-message="noDataMessage"
          :column-defs="columnDefs"
          :fetching="fetching"
          :row-data="rules"
          :row-actions="rowActions"
          :selection.sync="selection"
          :framework-components="frameworkComponents"
          v-on="!selectionType ? { rowClicked } : {}"
          @refresh="fetchData(true)"
        />
      </template>
    </grid-wrapper>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import {
    Type,
    ConditionsRenderer,
    ConditionGroupsRenderer,
    ActionRenderer,
    AssociatedPolicyRenderer,
    AssociatedApplianceRenderer,
    NameRenderer,
    columnDefs,
    rulesConfig,
  } from 'vuntangle/pm'
  import GridWrapper from '../components/GridWrapper.vue'
  import { hydrateRulesData } from '../hydration'

  import { generateDefaultMFWObject } from '../util'
  import renderersMixin from '../renderersMixin'
  import i18n from '@/plugins/vue-i18n'

  export default {
    components: { GridWrapper },
    mixins: [renderersMixin],
    data() {
      return {
        fetching: false,
        selection: [],
        frameworkComponents: {
          ConditionsRenderer,
          ConditionGroupsRenderer,
          ActionRenderer,
          AssociatedPolicyRenderer,
          AssociatedApplianceRenderer,
          NameRenderer,
        },

        ruleTypes: [
          Type.RuleSecurity,
          // Type.RuleWanPolicy, // CD-6048 avoid listing WAN Rules
          Type.RulePortForward,
          Type.RuleNat,
          Type.RuleShaping,
          Type.RuleApplicationControl,
          Type.RuleGeoipFilter,
          Type.RuleWebFilter,
          Type.RuleThreatPrevention,
          Type.RuleDns,
          Type.RuleCaptivePortal,
        ],

        rowActions: [
          {
            icon: 'mdi-pencil',
            handler: ({ node }) => this.rowClicked({ node }),
          },
          {
            icon: 'mdi-content-copy',
            handler: ({ data }) => this.onCloneRule({ id: data.id }),
          },
          {
            icon: 'mdi-delete',
            handler: ({ data }) => this.$refs.wrapper.onDelete([data.id]),
          },
        ],
      }
    },

    computed: {
      rules: ({ $store, ruleTypes }) => {
        let rules = []
        ruleTypes.forEach(type => {
          const rulesByType = $store.getters['policyManager/getObjectsByType'](type) || []
          rules = [...new Set([...rules, ...rulesByType])]
        })
        return hydrateRulesData(rules)
      },

      columnDefs: ({ augmentColumns }) =>
        augmentColumns(columnDefs.getRulesColumnDefs(), ['action', 'policies', 'appliances']),

      /** navigation items for each policy type */
      menuItems: () => {
        const groups = {}
        Object.entries(rulesConfig).forEach(([key, config]) => {
          if (!groups[config.category]) groups[config.category] = []
          groups[config.category].push({
            key,
            text: config.text,
            type: key,
          })
        })
        /** CD-4403 - alpha sort rule types with respect to their translations */
        for (const item in groups) {
          groups[item].sort((a, b) => i18n.t(a.text).localeCompare(i18n.t(b.text)))
        }
        return groups
      },
      noDataMessage() {
        return this.rules.length > 0 ? this.$t('no_filtered_data_rules') : this.$t('no_data_defined_rules')
      },
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      /**
       * dispatch action to fetch data needed for the grid
       * @param {Boolean} force - flag to force or not fetching data
       */
      async fetchData(force = false) {
        this.fetching = true
        await Promise.allSettled([
          // need to fetch appliances and assignments to show data in Appliances column
          this.$store.dispatch('appliances/fetchAppliances', { force }),
          this.$store.dispatch('policyManager/getAllApplianceAssignments', { force }),
          this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-rule', force }),
          this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config', force }),
          this.$store.dispatch('policyManager/fetchDependencyMap', { force }),
        ])
        this.fetching = false
      },

      /** commits the rule that is going to be edited, then routes to the rule editor */
      rowClicked({ node }) {
        const ruleId = node.data.id
        const rule = this.$store.getters['policyManager/getObjectById'](ruleId)
        if (!rule) return
        this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(rule))
        this.$router.push({ name: 'pm-rules-rule', params: { ruleId } })
      },

      /**
       * commits a new rule that is going to be edited, then routes to the rule editor
       * newly created objects are using temporary object Type as Id to avoid increasing route complexity
       * @param {String} type - the rule type to be created
       * @param {String} id - the object (rule) id to be duplicated
       */
      onCreate({ type, id }) {
        const rule = id && this.$store.getters['policyManager/getObjectById'](id)
        const ruleType = rule?.Type || type

        this.$store.commit('policyManager/SET_EDIT_OBJECT', generateDefaultMFWObject(ruleType, rule))
        // create new rule: type is 'mfw-[type]-rule', id is undefined
        // clone: type is undefined, id is uuid
        const isClone = !!(type === undefined && id)
        this.$router.push({ name: 'pm-rules-rule', params: { ruleId: ruleType, isClone } })
      },

      /**
       * Clones the entire rule including conditions as a new rule, then routes to edit page of new rule
       *
       * @param id - rule id to be cloned
       */
      async onCloneRule({ id }) {
        this.fetching = true
        // get the rule so we can get the type
        const rule = this.$store.getters['policyManager/getObjectById'](id)
        if (!rule) {
          this.$vuntangle.toast.add(this.$t('rule_cloned_failure'), 'error')
          this.fetching = false
          return
        }
        const clonedRule = await this.$store.dispatch('policyManager/cloneRule', { type: rule.Type, id: rule.Id })
        if (clonedRule === null) {
          this.$vuntangle.toast.add(this.$t('rule_cloned_failure'), 'error')
          this.fetching = false
          return
        }
        this.$vuntangle.toast.add(this.$t('rule_cloned_success'))
        // set the edit object as the cloned rule
        this.$store.commit('policyManager/SET_EDIT_OBJECT', clonedRule)
        // fetch all again
        await this.fetchData()
        // route to the new or cloned rule
        this.$router.push({ name: 'pm-rules-rule', params: { ruleId: clonedRule.Id } })
      },
    },
  }
</script>
