import cloneDeep from 'lodash/cloneDeep'
import { set } from 'vue'
import api from '@/plugins/ut/ut-api'
import user from '@/plugins/ut/ut-users'
import { getPolicy } from '@/util/mfwPolicies'

const getDefaultState = () => {
  return {
    list: null,
    fetching: false,
    unassignedList: null,
    fetchingUnassigned: false,
    mfwPolicies: null,
    applianceSettings: null,
  }
}

const getters = {
  list: state => state.list,
  listByType: state => type => state.list?.filter(item => item.ProductLine === type),
  fullList: state => (state.list && state.unassignedList ? [...state.list, ...state.unassignedList] : null),
  fetching: state => state.fetching,
  getByUid: state => Uid => {
    return state.list?.find(appliance => appliance.Uid === Uid)
  },
  getMfwAppliances: state => state.list?.filter(appliance => appliance.ProductLine === 'MFW'),
  getNgfwAppliances: state => state.list?.filter(appliance => appliance.ProductLine === 'NGFW'),
  getByUniqueIdentifier: state => id =>
    state.list?.find(appliance => appliance.UniqueIdentifier === id) ||
    state.unassignedList?.find(appliance => appliance.UniqueIdentifier === id),
  // return just the unique identifier of an appliance
  getUniqueIdentifierByUid: state => id => {
    let appliance = state.list?.find(appliance => appliance.Uid === id)
    if (!appliance) {
      appliance = state.list?.find(appliance => appliance?.SerialNumber === id)
    }
    return appliance ? appliance.UniqueIdentifier : ''
  },
  // get list of the appliance uids that are licensed for cmd
  getCmdEnabledAppliances: state => {
    const uids = []
    state.list?.forEach(appliance => {
      if (appliance.IsLicensedForCommandCenter) {
        uids.push(appliance.Uid)
      }
    })

    return uids
  },

  /**
   * getUpgradableAppliances will retrieve upgradable appliances
   * @returns {Array} - array of appliances
   */
  upgradable: state => {
    return state.list?.filter(appl => !(appl.uptime <= 0 || !appl.IsLicensedForCommandCenter || appl.isUpdating))
  },

  mfwPolicies: state => state.mfwPolicies,

  getApplianceSettings: state => state.applianceSettings,

  /** generates an id/name map of interfaces, e.g. { "1": "internal", "2": "WAN0" }, used for rendering */
  interfaceIdNameMap: state =>
    state.applianceSettings?.network.interfaces.reduce((res, intf) => {
      return { ...res, [intf.interfaceId]: intf.name }
    }, {}),

  /**
   * returns an array of interface items used in selects or renderers
   * @returns Array of interfaces as { text: name, value: id }
   */
  zoneInterfaces: state => {
    const interfaces = state.applianceSettings?.network.interfaces

    if (!interfaces) return []

    /** returning only addressed interfaces, to check if it needs to be only WANs */
    const addressedIntf = []
    interfaces.forEach(intf => {
      if (intf.configType === 'ADDRESSED') {
        addressedIntf.push({ text: intf.name, value: intf.interfaceId })
      }
    })
    return addressedIntf
  },

  /**
   * Joins any data set with appliances by appliance UID
   * @param {Array} list the data source to merge appliances with
   * @param {string} uidKey name of the property to join by
   * @param {string} newKey name of the new property in which to populate the appliance info
   */
  populateApplianceInfoByUid:
    state =>
    (list, uidKey, newKey = 'appliance') =>
      (list || []).map(row => {
        const appliance = state.list?.find(appliance => appliance.Uid === row[uidKey])
        return { ...row, [newKey]: appliance }
      }),
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_APPLIANCES: (state, appliances) => (state.list = appliances),
  SET_UNASSIGNED_APPLIANCES: (state, appliances) => (state.unassignedList = appliances),
  SET_FETCHING: (state, value) => (state.fetching = value),
  SET_FETCHING_UNASSIGNED: (state, value) => (state.fetchingUnassigned = value),
  UPDATE_APPLIANCE: (state, appliance) => {
    const applianceIndex = state.list.findIndex(r => r.Uid === appliance.Uid)
    set(state.list, applianceIndex, appliance)
  },
  ADD_APPLIANCE: (state, appliance) => {
    // check which store to add the appliance
    const checkList = appliance.Uid ? state.list : state.unassignedList
    checkList.push(appliance)
  },
  UPDATE_APPLIANCE_TAG: (state, { uid, tag }) => {
    const appliance = state.list.find(appl => appl.Uid === uid)
    set(appliance, 'ApplianceTag', tag)
  },
  UPDATE_APPLIANCE_WIFI_REGION: (state, { uid, wifiRegion }) => {
    const appliance = state.list.find(appl => appl.Uid === uid)
    if (appliance) {
      set(appliance, 'ApplianceWifiRegion', wifiRegion)
    }
  },
  UPDATE_APPLIANCE_LOCATION: (state, { uid, location, latitude, longitude }) => {
    const appliance = state.list.find(appl => appl.Uid === uid)
    if (appliance) {
      set(appliance, 'ApplianceLocation', location)
      set(appliance, 'ApplianceLatitude', latitude)
      set(appliance, 'ApplianceLongitude', longitude)
    }
  },
  SET_APPLIANCE_HAS_TOTP: (state, { uid, hasTotp }) => {
    const appliance = state.list.find(appl => appl.Uid === uid)
    if (appliance) {
      set(appliance, 'ApplianceHasTotp', hasTotp)
    }
  },
  DELETE_APPLIANCE: (state, appliance) => {
    // check which store list to search
    const checkList = appliance.Uid ? state.list : state.unassignedList

    const applianceIndex = checkList?.findIndex(f => f.UniqueIdentifier === appliance.UniqueIdentifier)
    if (applianceIndex !== -1) {
      checkList.splice(applianceIndex, 1)
    }
  },

  SET_MFW_POLICIES: (state, policies) => (state.mfwPolicies = policies),

  SET_APPLIANCE_SETTINGS: (state, settings) => {
    state.applianceSettings = settings
  },

  // update rule settings based on rule type
  SET_RULE_SETTINGS: (state, { ruleType, ruleSettings }) => {
    const newSettings = cloneDeep(state.applianceSettings)
    if (ruleType === 'wan-rules') {
      newSettings.wan = ruleSettings
    } else {
      newSettings.firewall.tables[ruleType] = ruleSettings
    }
    state.applianceSettings = newSettings
  },

  SET_CAPTIVE_PORTAL_IMAGE_DATA: (state, imageData) => {
    if (state.applianceSettings?.captiveportal) {
      set(state.applianceSettings.captiveportal, 'logo', { imageData })
    }
  },
}

const actions = {
  async fetchAppliances({ state, commit }, options) {
    // do not call the ajax if the call is already fetching or the list exists
    // but a 'force' was not set
    if (state.fetching || (state.list !== null && !options?.force)) {
      return
    }

    commit('SET_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetAppliances', {
      getExtendedInfo: true,
      paramOrder: 'getExtendedInfo',
      overrideSession: true,
    })

    if (response.success && response.data) {
      const appliances = response.data
      const sortedOnlineFirst = appliances.sort(a => {
        return a.StatusFlags[0].Messages[0] === 'appliance_status_online' ? -1 : 1
      })
      commit('SET_APPLIANCES', sortedOnlineFirst)
    } else {
      commit('SET_APPLIANCES', [])
    }

    commit('SET_FETCHING', false)
  },

  /**
   * Fetch appliances that do not have a UID and only a serial number.  This is used internally by
   * fetchFullAppliances.
   *
   * @param {Object}    state
   * @param {Function}  commit
   * @param {Object}    options
   *
   * @returns {Promise<void>}
   */
  async fetchUnassignedAppliances({ state, commit }, options) {
    // do not call the ajax if the call is already fetching or the list exists
    // but a 'force' was not set
    if (state.fetchingUnassigned || (state.unassignedList !== null && !options?.force)) {
      return
    }

    // make sure the user has access, otherwise make this an empty array to show it was fetched
    if (!user.userHasAccess('appliances', 'full')) {
      commit('SET_UNASSIGNED_APPLIANCES', [])
      return
    }

    commit('SET_FETCHING_UNASSIGNED', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetUnassignedSerialNumbers')

    if (response.success && response.data) {
      commit('SET_UNASSIGNED_APPLIANCES', response.data)
    } else {
      commit('SET_UNASSIGNED_APPLIANCES', [])
    }

    commit('SET_FETCHING_UNASSIGNED', false)
  },

  /**
   * Call two ajax requests serially to get the full list of appliances (assigned and unassigned).
   *
   * @param {Function} dispatch
   *
   * @returns {Promise<any>}
   */

  fetchFullAppliances: async ({ dispatch }, options) =>
    await Promise.all([dispatch('fetchAppliances', options), dispatch('fetchUnassignedAppliances', options)]),
  /**
   * Update the 'NetworkInfo' for an array of appliance uids.  This is used
   * when changing associations between appliances and networks.
   *
   * @param {Function} commit
   * @param {Object}   getters
   * @param {Array}    uids
   * @param {String|null}   networkInfo
   */
  updateAppliancesNetworkInfo({ commit, getters }, { uids, networkInfo }) {
    uids.forEach(uid => {
      // try to find the appliance
      const appliance = getters.getByUid(uid)
      if (!appliance) {
        return
      }

      // clone the appliance, update the network, and commit to the store
      const updatedAppliance = cloneDeep(appliance)
      updatedAppliance.NetworkInfo = networkInfo
      commit('UPDATE_APPLIANCE', updatedAppliance)
    })
  },

  /**
   * Async call to do all setup needed for adding any NGFW or MFW appliance
   * @typedef {Object} args
   * @property {Function} commit
   *
   * @param {args} param
   *
   * @param {Object} data
   * @param {String} data.uid - UID of appliance to be added
   * @param {String} data.adminUser - userName for login
   * @param {String} data.adminPassword - password for login
   * @param {String} data.accountEmail - email address for account
   * @param {String} data.timeZone - timezone for appliance
   * @param {String} data.openWrtTimeZone - timezone for appliances requiring openwrt for setting timezone (mfw)
   * @param {String} data.installType - type of install
   * @param {String} data.newDomainname - domain name to set appliance to
   * @param {String} data.newHostname - hostname for appliance
   * @param {String} data.newLocation - appliance location
   * @param {String} data.newTag - value to set appliance tag
   * @param {String} data.subscription - name of subscription to assign to appliance, or null
   * @param {String} data.masterUID - UID template to copy to this appliance
   * @param {String} data.prodType - whether appliance being passed back is NGFW, MFW
   * @param {String} data.paramOrder - "uid adminUser adminPassword accountEmail timeZone openWrtTimeZone installType newDomainname newHostname newLocation newTag subscription masterUID"
   */
  async addAndConfigureAppliance({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'AddAndConfigureAppliance', data)
    if (response.success && response.data) {
      // if back end sent a new appliance object back, update the store
      if (response.data.appliance && !response.data.existingAppliance) commit('ADD_APPLIANCE', response.data.appliance)
      // return this for appliance info
      return response.data
    }
    return { message: 'assign_appliance_organization_failed' }
  },

  /**
   * Async call requesting any information cmd server has on the specified appliance
   *
   * @param {Object} data
   * @param {String} data.uid- The new appliance UID or serial
   * @param {String} data.paramOrder - "uid"
   * @returns {Promise<*>}
   */
  async findAppliance({}, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'FindAppliance', data)
    return response
  },

  /**
   * Async action call to add a new appliance
   *
   * @param {Object} data
   * @param {String} data.uids - Array of the appliances UID to be updated
   * @param {String} data.paramOrder - "uids"
   */
  async updateAppliances({}, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateAppliances', data)
    if (response.success) {
      // TODO - handle response data
    }

    return response
  },

  /**
   * Async action call to add an appliance new tag
   * @typedef {Object} args
   * @property {Function} commit
   *
   * @param {args} param
   *
   * @param {Object} data
   * @param {String} data.uid - The appliance UID for which the tag is added
   * @param {String} data.newTag - The new tag to be set
   * @param {String} data.paramOrder - "uid newTag"
   */
  async setApplianceTag({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateApplianceTag', data)
    if (response.data) {
      commit('UPDATE_APPLIANCE_TAG', { uid: data.uid, tag: data.newTag })
      return true
    }
    return false
  },

  /**
   * Async action call to remove appliances
   *
   * @param {Object[]} appliances array of appliance objects to remove
   *
   * @returns {Promise<Object>}
   */
  async removeAppliances({}, appliances) {
    return await api.cloud('Untangle_CommandCenter', 'RemoveAppliances', {
      appliances: appliances.map(a =>
        a.Uid ? a.Uid : a.ApplianceSerialNumber ? a.ApplianceSerialNumber : a.SerialNumber,
      ),
      paramOrder: 'appliances',
    })
  },

  /**
   * Async action call update appliance location
   * @typedef {Object} args
   * @property {Function} commit
   *
   * @param {args} param
   *
   * @param {Object} data
   * @param {String} data.uid - The array of appliances to be removed
   * @param {String} data.newLocation - The array of appliances to be removed*
   * @param {String} data.paramOrder - "uid newLocation"
   */
  async updateLocation({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateApplianceLocation', data)

    if (response.success && response.data) {
      commit('UPDATE_APPLIANCE_LOCATION', {
        uid: data.uid,
        location: data.newLocation,
        latitude: response.data.ApplianceLatitude,
        longitude: response.data.ApplianceLongitude,
      })
    }
    return response.success && response.data
  },

  /**
   * Async action call to update appliance wifi region
   * @typedef {Object} args
   * @property {Function} commit
   *
   * @param {args} param
   *
   * @param {Object} data
   * @param {String} data.uid - The appliance UID for which the wifi region is updated
   * @param {String} data.newWifiRegion - The new wifi region to be set
   * @param {String} data.paramOrder - "uid newWifiRegion"
   */
  async setWifiRegion({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateApplianceWifiRegion', data)

    if (!response.data) {
      return false
    }
    commit('UPDATE_APPLIANCE_WIFI_REGION', { uid: data.uid, wifiRegion: data.newWifiRegion })

    return true
  },

  /**
   * Async action call to set appliance totp
   */
  async addApplianceTotp({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'AddTotpToAppliance', data)

    if (response?.data.success) {
      commit('SET_APPLIANCE_HAS_TOTP', { uid: data.uid, hasTotp: true })
    }

    return response
  },

  /**
   * Async action call to remove appliance totp
   */
  async removeApplianceTotp({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'RemoveTotpToAppliance', data)

    if (response?.data.success) {
      commit('SET_APPLIANCE_HAS_TOTP', { uid: data.uid, hasTotp: false })
    }

    return response
  },

  /**
   * Async action returns all backups associated with account for all appliances
   * @returns object
   */
  async fetchAccountBackups() {
    return await api.cloud('Untangle_CommandCenter', 'GetAccountBackups')
  },

  async fetchMfwPolicies({ state, commit }, options) {
    if (state.fetching || (state.mfwPolicies !== null && !options?.force)) {
      return
    }

    commit('SET_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetPolicies', {
      type: 'mfw-',
      includeJson: true,
      partialMatch: true,
      paramOrder: 'type includeJson partialMatch',
    })

    if (response.success && response.data) {
      commit('SET_MFW_POLICIES', response.data)
    } else {
      commit('SET_MFW_POLICIES', [])
    }
    commit('SET_FETCHING', false)
  },

  /**
   * Fetches full appliance settings, than commits recieved data to store
   * It uses the triesCount needed in some cases when the box is not responding right away
   * @param {Object} appliance - appliance to fetch settings for
   */
  async fetchApplianceSettings({ commit }, appliance) {
    // if device is connected to CMD follow the rety logic,
    if (appliance.IsConnectedToCmd) {
      const triesCount = 20
      for (let i = 0; i < triesCount; i++) {
        const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceSetting', {
          uid: appliance.Uid,
          setting: '',
          paramOrder: 'uid setting',
        })
        if (response.success && response.data) {
          commit('SET_APPLIANCE_SETTINGS', response.data)
          return
        }
      }
    }
    // device not connected to CMD, fetch settings via cloud instead
    if (appliance.ApplianceSettingsId) {
      const response = await getPolicy(appliance.ApplianceSettingsId)
      if (response.success && response.data?.PolicyJson) {
        commit('SET_APPLIANCE_SETTINGS', response.data.PolicyJson)
        return
      }
    }
    // clear old appliance settings from store
    commit('SET_APPLIANCE_SETTINGS', null)
    if (appliance.IsConnectedToCmd) window.location.reload()
  },

  /**
   * Fetch the captive portal image.  This requires a separate endpoint than fetching the captive portal data because
   * it is not stored in the settings json on the appliance.
   *
   * @param commit
   * @param getters
   * @param uid
   *
   * @returns {Promise<void>}
   */
  async fetchCaptivePortalImage({ commit }, uid) {
    const response = await api.cloud('Untangle_CommandCenter', 'GetFromApplianceApi', {
      uid,
      path: 'captiveportal/image',
      paramOrder: 'uid path',
    })

    // imageData must be set at least to an emty string (no image) regardless of the response
    commit('SET_CAPTIVE_PORTAL_IMAGE_DATA', response.data?.imageData || '')
  },

  /**
   * Save the captive portal image.  This requires a separate endpoint than saving the captive portal data because
   * it is not stored in the settings json on the appliance.
   *
   * @param uid
   * @param payload
   *
   * @returns {Promise<Boolean>}
   */
  async saveCaptivePortalImage({}, { uid, payload }) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        paramOrder: 'payload',
        method: 'SendToApplianceApi',
      },
      {
        uid,
        path: 'captiveportal/image',
        payload,
        method: 'POST',
      },
    )

    return response.success && response.data?.success
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
